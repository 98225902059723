$js30-color: #ff641e;
$xy60-color: #247cff;
$kx90-color: #55cc00;
$sk120-color: rgb(4, 154, 137);
$jnd210-color: #e52c2c;
$tw300-color: #ae2ce5;

.bet-container {
  .bet-title {
    height: 50px;
    background: #e6e6e6;
    margin-top: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 50px;
    text-align: center;
    font-style: normal;
    &.js30 {
      span {
        color: $js30-color;
      }
    }
    &.xy60 {
      span {
        color: $xy60-color;
      }
    }
    &.kx90 {
      span {
        color: $kx90-color;
      }
    }
    &.sk120 {
      span {
        color: $sk120-color;
      }
    }
    &.jnd210 {
      span {
        color: $jnd210-color;
      }
    }
    &.tw300 {
      span {
        color: $tw300-color;
      }
    }
  }
  .bet-row1,
  .bet-row2 {
    margin-top: 11px;
    & > .ant-col {
      width: 72px;
      height: 44px;
      background: #f5f5f5;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      font-style: normal;
      cursor: pointer;
      &.current {
        background-color: #ff641e;
        border-color: #ff641e;
        color: #fff;
      }
    }
    &.js30 {
      & > .ant-col {
        &.current {
          background-color: $js30-color;
          border-color: $js30-color;
        }
      }
    }
    &.xy60 {
      & > .ant-col {
        &.current {
          background-color: $xy60-color;
          border-color: $xy60-color;
        }
      }
    }
    &.kx90 {
      & > .ant-col {
        &.current {
          background-color: $kx90-color;
          border-color: $kx90-color;
        }
      }
    }
    &.sk120 {
      & > .ant-col {
        &.current {
          background-color: $sk120-color;
          border-color: $sk120-color;
        }
      }
    }
    &.jnd210 {
      & > .ant-col {
        &.current {
          background-color: $jnd210-color;
          border-color: $jnd210-color;
        }
      }
    }
    &.tw300 {
      & > .ant-col {
        &.current {
          background-color: $tw300-color;
          border-color: $tw300-color;
        }
      }
    }
  }
  .bet-row2 {
    & > .ant-col {
      &.transparent {
        background-color: transparent;
      }
      &.btn3 {
        width: 231px;
        height: 38px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        margin-right: 8px;
        display: block;
        margin-right: 87px;
        .ant-col {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .input-box {
          width: 112px;
          border-right: 1px solid #d9d9d9;
          .ant-input {
            padding: 6px 11px;
            border: none;
          }
        }
        .btn4 {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          font-style: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          cursor: pointer;
          img {
            width: 18px;
            height: 18px;
            margin-right: 4px;
            vertical-align: middle;
          }
        }
        .btn5 {
          flex: 1;
          background: #ff641e;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          font-style: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 38px;
          cursor: pointer;
          &.js30 {
            background: $js30-color;
          }
          &.xy60 {
            background: $xy60-color;
          }
          &.kx90 {
            background: $kx90-color;
          }
          &.sk120 {
            background: $sk120-color;
          }
          &.jnd210 {
            background: $jnd210-color;
          }
          &.tw300 {
            background: $tw300-color;
          }
        }
      }
    }
  }
  .bet-row3 {
    margin-top: 20px;
    .btn1,
    .btn2 {
      width: 109px;
      height: 38px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      font-style: normal;
      margin-right: 8px;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }
    .btn2 {
      width: 81px;
    }
    .btn1 {
      &.back {
        margin-right: 0;
        margin-left: 240px;
        a {
          color: #333;
          display: block;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .btn3 {
      width: 281px;
      height: 38px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      margin-right: 8px;
      .ant-col {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .input-box {
        width: 162px;
        border-right: 1px solid #d9d9d9;
        .ant-input {
          padding: 6px 11px;
          border: none;
        }
      }
      .btn4 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        cursor: pointer;
        img {
          width: 18px;
          height: 18px;
          margin-right: 4px;
          vertical-align: middle;
        }
      }
      .btn5 {
        flex: 1;
        background: #ff641e;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        cursor: pointer;
        &.js30 {
          background: $js30-color;
        }
        &.xy60 {
          background: $xy60-color;
        }
        &.kx90 {
          background: $kx90-color;
        }
        &.sk120 {
          background: $sk120-color;
        }
        &.jnd210 {
          background: $jnd210-color;
        }
        &.tw300 {
          background: $tw300-color;
        }
      }
    }
  }
  .table-box {
    margin-top: 12px;
    & > .ant-col {
      table {
        border-collapse: collapse;
        width: 564px;
        th.ant-table-cell {
          height: 50px;
          background: #e6e6e6;
          border-bottom: none;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: #333333;
          font-style: normal;
          text-align: center;
          &::before {
            display: none;
          }
          &:first-child {
            border-radius: 0 !important;
          }
          &:last-child {
            border-radius: 0 !important;
          }

          & + td.ant-table-cell {
            height: 50px;
            background: #e6e6e6;
            border: none;
            &::before {
              display: none;
            }
          }
        }
        td.ant-table-cell {
          height: 50px;
          border: 1px solid #d9d9d9;
          text-align: center;
          padding: 0 !important;
          .table-check {
            margin: 0 5px;
            &.js30 {
              .ant-checkbox-checked {
                &::after {
                  border-color: $js30-color;
                }
                .ant-checkbox-inner {
                  background-color: $js30-color;
                  border-color: $js30-color;
                }
              }
            }
            &.xy60 {
              .ant-checkbox-checked {
                &::after {
                  border-color: $xy60-color;
                }
                .ant-checkbox-inner {
                  background-color: $xy60-color;
                  border-color: $xy60-color;
                }
              }
            }
            &.kx90 {
              .ant-checkbox-checked {
                &::after {
                  border-color: $kx90-color;
                }
                .ant-checkbox-inner {
                  background-color: $kx90-color;
                  border-color: $kx90-color;
                }
              }
            }
            &.sk120 {
              .ant-checkbox-checked {
                &::after {
                  border-color: $sk120-color;
                }
                .ant-checkbox-inner {
                  background-color: $sk120-color;
                  border-color: $sk120-color;
                }
              }
            }
            &.jnd210 {
              .ant-checkbox-checked {
                &::after {
                  border-color: $jnd210-color;
                }
                .ant-checkbox-inner {
                  background-color: $jnd210-color;
                  border-color: $jnd210-color;
                }
              }
            }
            &.tw300 {
              .ant-checkbox-checked {
                &::after {
                  border-color: $tw300-color;
                }
                .ant-checkbox-inner {
                  background-color: $tw300-color;
                  border-color: $tw300-color;
                }
              }
            }
          }
          .num-box {
            cursor: pointer;
            width: 36px;
            height: 36px;
            background: #f5f6f5;
            border: 1px solid #d9d9d9;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            font-style: normal;
            border-radius: 100%;
            margin: 0 auto;
            &.js30 {
              background-color: $js30-color;
              border-color: $js30-color;
              color: #fff;
            }
            &.xy60 {
              background-color: $xy60-color;
              border-color: $xy60-color;
              color: #fff;
            }
            &.kx90 {
              background-color: $kx90-color;
              border-color: $kx90-color;
              color: #fff;
            }
            &.sk120 {
              background-color: $sk120-color;
              border-color: $sk120-color;
              color: #fff;
            }
            &.jnd210 {
              background-color: $jnd210-color;
              border-color: $jnd210-color;
              color: #fff;
            }
            &.tw300 {
              background-color: $tw300-color;
              border-color: $tw300-color;
              color: #fff;
            }
          }
          .table-input {
            width: 79px;
          }
          .times-box {
            & > .ant-col {
              width: 52px;
              height: 36px;
              background: #ffffff;
              border-radius: 2px;
              border: 1px solid #d9d9d9;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #333333;
              font-style: normal;
              margin: 0 2px;
              cursor: pointer;
              &.current {
                background: #ff641e;
                border-color: #ff641e;
                color: #fff;
              }
            }
          }
        }
        tr:nth-child(2n) {
          td.ant-table-cell {
            background: #f5f5f5;
          }
        }
      }
    }
  }
  .tip {
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 12px;
    font-style: normal;
    margin: 23px 0;
  }
}
