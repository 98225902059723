.top-nav {
  height: 62px;
  background: #ff641e;
  backdrop-filter: blur(11.02941176470588px);
  & > .ant-row {
    width: 1200px;
    margin: 0 auto;
    & > .ant-col {
      margin-right: 24px;
      cursor: pointer;
      font-family: PingFang HK, PingFang HK;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      height: 42px;
      display: inline-block;
      line-height: 42px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      &.current {
        font-family: PingFang HK, PingFang HK;
        font-weight: 500;
        color: #ff641e;
        background: #ffffff;
        border-radius: 21px;
      }
    }
  }
}
.top-menu {
  & > .ant-col {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 14px;
    position: relative;
    &.current {
      a {
        font-weight: 600;
        color: #3061f5;
        font-size: 16px;
      }
      &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 3px;
        background: #3061f5;
        border-radius: 2px;
        opacity: 0.95;
        bottom: -10px;
        left: 50%;
        margin-left: -8px;
      }
    }
    a,
    span {
      padding: 0 16px;
      color: #666666;
      cursor: pointer;
    }
  }
}
