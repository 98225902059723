.footer-box {
  height: 175px;
  background: #252525;
  padding-top: 20px;
  .logo {
    text-align: center;
    height: 44px;
    img {
      width: 120px;
      height: 44px;
    }
  }
  .bottom-menu {
    margin-top: 20px;
    & > .ant-col {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 14px;
      padding: 0 8px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 14px;
        background: #454545;
        right: 0;
        top: 50%;
        margin-top: -7px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      a {
        color: #999999;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .line {
    width: 1200px;
    height: 1px;
    background: #454545;
    opacity: 0.5;
    margin: 16px auto;
  }
  .footer-info {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin-top: 10px;
    a {
      color: #999;
    }
    & > .ant-col {
      margin: 0 10px;
      font-size: 12px;
    }
    img {
      width: 18px;
      height: 21px;
      margin-right: 6px;
      vertical-align: middle;
    }
  }
}
