.activity-container {
  background-color: #faf7f5;
  padding: 0 0 40px;
  .banner-contaner {
    width: 100%;
    height: 260px;
    position: relative;
    background-color: #f5c07d;
    .banner-img {
      width: 100%;
      height: 260px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
  .activity-main {
    width: 1200px;
    margin: 18px auto 0;
    .left-nav {
      width: 250px;
      height: 917px;
      background: #ffffff;
      box-shadow: 0px 2px 16px 0px rgba(150, 88, 60, 0.1);
      border-radius: 6px;
      .nav-title {
        height: 80px;
        background: url('../../assets/activity/nav-title.png') no-repeat;
        background-size: 100% 100%;
        font-size: 0;
      }
      .nav-menu {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        font-style: normal;
        margin-top: 20px;
        & > .ant-row {
          height: 60px;
          cursor: pointer;
          margin-bottom: 8px;
          &.current {
            color: #ff641e;
            background: #fff1cf;
          }
        }
        .nav-icon {
          width: 23px;
          height: 23px;
          margin-right: 9px;
        }
      }
    }
    .right-main {
      width: 932px;
      background: #ffffff;
      box-shadow: 0px 2px 16px 0px rgba(150, 88, 60, 0.1);
      border-radius: 6px;
      padding-bottom: 20px;
      .right-title {
        height: 48px;
        background: linear-gradient(180deg, #ffb694 0%, #ff641e 100%);
        border-radius: 6px 6px 0 0;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        line-height: 16px;
        font-style: normal;
        padding-left: 20px;
        .title-icon {
          width: 18px;
          height: 19px;
          margin-right: 6px;
        }
      }
      .vip-box {
        margin: 20px 0 0 20px;
        & > .ant-col {
          width: 217px;
          height: 300px;
          background: #fff7f0;
          border-radius: 6px;
          margin-right: 8px;
          position: relative;
          .vip-img {
            width: 217px;
            height: 217px;
            display: flex;
            justify-content: center;
            align-items: center;
            & > img {
              max-width: 217px;
              max-height: 217px;
            }
          }
          .vip-btn {
            height: 83px;
            padding: 0 10px;
            .price {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #ff641e;
              font-style: normal;
            }
            .btn {
              width: 68px;
              height: 35px;
              background: #ff641e;
              border-radius: 4px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #fff7f0;
              font-style: normal;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
          .name-box {
            position: absolute;
            width: 82px;
            height: 38px;
            border-radius: 15px 0 15px 0;
            top: 0;
            left: 0;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #fff7f0;
            font-style: normal;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &:nth-of-type(4n-3) {
            .name-box {
              background: linear-gradient(180deg, #e4a96e 0%, #a87037 100%);
            }
          }
          &:nth-of-type(4n-2) {
            .name-box {
              background: linear-gradient(180deg, #98c9d0 0%, #5a9299 100%);
            }
          }
          &:nth-of-type(4n-1) {
            .name-box {
              background: linear-gradient(180deg, #f4c4f0 0%, #b06fab 100%);
            }
          }
          &:nth-of-type(4n) {
            .name-box {
              background: linear-gradient(180deg, #c5c5f4 0%, #706fb1 100%);
            }
          }
          .vip-time {
            position: absolute;
            width: 100%;
            height: 30px;
            background-color: rgba(0, 0, 0, 0.6);
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #fff;
            line-height: 30px;
            text-align: left;
            font-style: normal;
            padding-left: 10px;
            left: 0;
            bottom: 83px;
          }
        }
      }
      .activity-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        padding-left: 13px;
        margin: 30px 0 0 20px;
        .title-icon {
          width: 8px;
          height: 8px;
          border: 2px solid #ff641e;
          border-radius: 100%;
          margin-right: 7px;
        }
      }
      .activity-rule {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666;
        line-height: 30px;
        text-align: left;
        font-style: normal;
        padding: 0 20px 0 40px;
        margin-top: 14px;
      }
      .activity-tip {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ff641e;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        margin: 24px 0 0 30px;
      }
      .activity-table {
        margin: 19px 30px 0;
        th.ant-table-cell {
          background-color: #f5f5f5;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #333333;
          font-style: normal;
          height: 50px;
          border-radius: 0 !important;
        }
        td.ant-table-cell {
          height: 50px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          font-style: normal;
          .table-btn {
            width: 80px;
            height: 30px;
            background: linear-gradient(180deg, #ffb694 0%, #ff641e 100%);
            border-radius: 3px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.disabled {
              background: gray;
              cursor: not-allowed;
            }
          }
        }
        tr:nth-of-type(even) {
          td.ant-table-cell {
            background-color: #f5f5f5;
          }
        }
      }

      .sub-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #666666;
        line-height: 25px;
        font-style: normal;
        text-align: center;
        margin-top: 38px;
        & > span {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            width: 17px;
            height: 16px;
            background: url(../../assets/ucenter/star-icon.png) no-repeat;
            background-size: 100% 100%;
            left: -23px;
            top: 50%;
            margin-top: -8px;
          }
          &::after {
            content: '';
            position: absolute;
            width: 17px;
            height: 16px;
            background: url(../../assets/ucenter/star-icon.png) no-repeat;
            background-size: 100% 100%;
            right: -23px;
            top: 50%;
            margin-top: -8px;
          }
        }
      }
      .info-box {
        width: 892px;
        height: 140px;
        background: #f4f4f4;
        border-radius: 4px;
        margin: 26px auto;
        .avatar-box {
          width: 179px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 110px;
            border: 1px dashed #c7c7c7;
            right: 0;
            top: 15px;
          }
        }
        .middle-box,
        .right-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 46px;
          width: 234px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          & > div {
            margin-bottom: 12px;
            &:last-child {
              margin-bottom: 0;
            }
            .balck {
              color: #333333;
            }
          }
        }
      }
      .data-box {
        padding-left: 20px;
        margin-top: 16px;
        margin-bottom: 110px;
        & > .ant-col {
          width: 295px;
          height: 158px;
          background: #f5f5f5;
          border-radius: 4px;
          margin-right: 5px;
          padding-top: 22px;
          margin-bottom: 5px;
        }
      }
      .data-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #888888;
        line-height: 20px;
        text-align: center;
        font-style: normal;
      }
      .data-num {
        font-family: DIN, DIN;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        margin-top: 14px;
      }
      .data-bottom {
        margin-top: 22px;
        border-top: 1px solid #e3e3e3;
        height: 57px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #d95316;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .form-box {
        margin-top: 40px;
        .form-lable {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          font-style: normal;
          width: 70px;
          text-align: right;
        }
        .form-input {
          width: 348px;
          height: 40px;
        }
        .form-btn {
          width: 348px;
          height: 40px;
          background: #d74e10;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          font-style: normal;
          margin: 10px 0 0 327px;
          cursor: pointer;
        }
      }
      .search-box {
        margin-top: 31px;
        padding: 0 20px;
        .ant-select-selection-item {
          line-height: 40px;
        }
        .ant-form-item {
          margin-right: 3px;
        }
        .search-input {
          width: 178px;
          height: 40px;
          .ant-input-number-input,
          .ant-select-selector {
            height: 40px;
          }
        }
        .search-btn1 {
          width: 82px;
          height: 40px;
          background: #d74e10;
          border-radius: 2px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          font-style: normal;
        }
        .search-btn2 {
          width: 152px;
          height: 40px;
          border-radius: 2px;
          border: 1px solid #3399fe;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #3399fe;
          font-style: normal;
          margin-left: 3px;
        }
        .use-btn {
          width: 102px;
          height: 40px;
          background: #faa624;
          border-radius: 2px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          font-style: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .page-box {
        margin-top: 15px;
        margin-bottom: 30px;
        & > .ant-col {
          margin: 0 10px;
        }
      }
      .level-box {
        padding: 0 20px;
        margin-top: 30px;
        & > .ant-col {
          text-align: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 50px;
          font-style: normal;
          & > div:first-child {
            height: 50px;
            background: #f5f5f5;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #333333;
            line-height: 50px;
            font-style: normal;
          }
        }
      }
      .sign-btns {
        margin-top: 20px;
        & > .ant-col {
          width: 180px;
          height: 40px;
          background: #d74e10;
          border-radius: 4px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          font-style: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.gray {
            background-color: rgba(215, 78, 16, 0.3);
          }
        }
      }
      .pwd-form {
        padding-left: 234px;
        .ant-form-item-label {
          line-height: 40px;
          & > label {
            width: 70px;
            text-align: right;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #333333;
            font-style: normal;
          }
        }
        .form-btn {
          margin-left: 70px;
        }
      }
      .bank-box {
        margin-top: 30px;
        padding: 0 20px;
        & > .ant-col {
          width: 442px;
          height: 410px;
          background: #fff8f1;
          border-radius: 4px;
          border: 1px solid #f4dec8;
          .bank-title {
            height: 49px;
            background: #d74e10;
            border-radius: 4px 4px 0px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
            font-style: normal;
          }
          .bank-coin {
            text-align: center;
            font-family: DIN, DIN;
            font-weight: 500;
            font-size: 20px;
            color: #e89907;
            line-height: 24px;
            font-style: normal;
            margin-top: 24px;
            .coin-icon {
              width: 15px;
              height: 15px;
              margin-left: 5px;
              vertical-align: middle;
            }
          }
          .bank-sub {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 20px;
            text-align: center;
            font-style: normal;
            margin-top: 9px;
          }
          .num-type {
            padding: 0 40px;
            margin-top: 40px;
            & > .ant-col {
              width: 60px;
              cursor: pointer;
              height: 36px;
              border-radius: 2px;
              border: 1px solid #cccccc;
              display: flex;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 14px;
              font-style: normal;
              justify-content: center;
              align-items: center;
              &.current {
                border: 1px solid #ff641e;
                background: rgba(255, 100, 30, 0.1);
                color: #ff641e;
              }
            }
          }
          .bank-form {
            padding-left: 40px;
            margin-top: 22px;
            &.pwd {
              margin-top: 12px;
            }
            .bank-label {
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              font-size: 14px;
              color: #333333;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
            .bank-input {
              width: 286px;
              height: 40px;
              border-radius: 2px;
              border: 1px solid #cccccc;
              background-color: #fff8f1;
              .ant-input-number-input {
                height: 40px;
              }
            }
          }
          .bank-btn {
            width: 362px;
            height: 40px;
            background: #d74e10;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 24px auto 0;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            font-style: normal;
            cursor: pointer;
          }
        }
      }
    }
    .luck-page {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

// 新弹框
.activity-modal {
  .ant-modal-content {
    padding: 0 !important;
    padding-bottom: 25px !important;
    padding-top: 42px !important;
    .modal-price {
      font-family: DIN, DIN;
      font-weight: 500;
      font-size: 37px;
      color: #ff641e;
      line-height: 37px;
      text-align: center;
      font-style: normal;
    }
    .modal-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      padding-left: 30px;
      margin-top: 47px;
    }
    .modal-vip {
      padding: 0 30px;
      margin-top: 13px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      font-style: normal;
      & > .ant-col:last-child {
        font-weight: 400;
      }
    }
    .modal-pay {
      margin: 0;
      padding: 0 30px;
      margin-top: 21px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      flex-direction: row-reverse;
      align-items: center;
      .pay-icon {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-right: 6px;
      }
      & > span:last-child {
        flex: 1;
        line-height: 20px;
        padding: 0;
      }
    }
    .modal-btn {
      width: 265px;
      height: 40px;
      background: #ff641e;
      border-radius: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      font-style: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px auto 0;
      cursor: pointer;
    }
  }
}
