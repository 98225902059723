$js30-color: #ff641e;
$xy60-color: #247cff;
$kx90-color: #55cc00;
$sk120-color: rgb(4, 154, 137);
$jnd210-color: #e52c2c;
$tw300-color: #ae2ce5;

$js30-color-trans: rgba(255, 100, 30, 0.1);
$xy60-color-trans: rgba(36, 124, 255, 0.1);
$kx90-color-trans: rgba(85, 204, 0, 0.1);
$sk120-color-trans: rgba(4, 154, 137, 0.1);
$jnd210-color-trans: rgba(229, 44, 44, 0.1);
$tw300-color-trans: rgb(174, 44, 229, 0.1);

$js30-color-trans5: rgba(255, 100, 30, 0.5);
$xy60-color-trans5: rgba(36, 124, 255, 0.5);
$kx90-color-trans5: rgba(85, 204, 0, 0.5);
$sk120-color-trans5: rgba(4, 154, 137, 0.5);
$jnd210-color-trans5: rgba(229, 44, 44, 0.5);
$tw300-color-trans5: rgb(174, 44, 229, 0.5);
.luckGame-container {
  width: 1200px;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(150, 88, 60, 0.1);
  border-radius: 6px;
  margin: 25px auto;
  padding: 24px 30px;
  .game-nav {
    margin-bottom: 12px;
    & > .ant-col {
      width: 180px;
      height: 44px;
      border-radius: 2px;
      border: 1px solid #ff641e;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #ff641e;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.js30 {
        border-color: $js30-color;
        color: $js30-color;
        &.current {
          background-color: $js30-color;
          color: #fff;
        }
      }
      &.xy60 {
        border-color: $xy60-color;
        color: $xy60-color;
        &.current {
          background-color: $xy60-color;
          color: #fff;
        }
      }
      &.kx90 {
        border-color: $kx90-color;
        color: $kx90-color;
        &.current {
          background-color: $kx90-color;
          color: #fff;
        }
      }
      &.sk120 {
        border-color: $sk120-color;
        color: $sk120-color;
        &.current {
          background-color: $sk120-color;
          color: #fff;
        }
      }
      &.jnd210 {
        border-color: $jnd210-color;
        color: $jnd210-color;
        &.current {
          background-color: $jnd210-color;
          color: #fff;
        }
      }
      &.tw300 {
        border-color: $tw300-color;
        color: $tw300-color;
        &.current {
          background-color: $tw300-color;
          color: #fff;
        }
      }
    }
  }
  .cur-left {
    width: 786px;
    height: 188px;
    background: #f5f5f5;
    border-radius: 2px;
    padding: 14px 10px;
    .font1 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      .orange {
        color: #ff641e;
        &.js30 {
          color: $js30-color;
        }
        &.xy60 {
          color: $xy60-color;
        }
        &.kx90 {
          color: $kx90-color;
        }
        &.sk120 {
          color: $sk120-color;
        }
        &.jnd210 {
          color: $jnd210-color;
        }
        &.tw300 {
          color: $tw300-color;
        }
      }
    }
    .font2 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333;
      line-height: 14px;
      text-align: left;
      font-style: normal;
    }
    .num-box {
      margin-top: 27px;
    }
    .num-bg1,
    .num-bg2,
    .num-bg3,
    .num-bg4 {
      width: 64px;
      height: 58px;
      background: url(../../assets/luckGame/num-bg1.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      font-family: DIN, DIN;
      font-weight: bold;
      font-size: 26px;
      color: #ffffff;
      font-style: normal;
      padding: 5px;
    }
    .num-bg2 {
      background: url(../../assets/luckGame/num-bg2.png) no-repeat;
      background-size: 100% 100%;
    }
    .num-bg3 {
      background: url(../../assets/luckGame/num-bg3.png) no-repeat;
      background-size: 100% 100%;
    }
    .num-bg4 {
      background: url(../../assets/luckGame/num-bg4.png) no-repeat;
      background-size: 100% 100%;
    }
    .minus-icon {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 26px;
      color: #999999;
      line-height: 37px;
      font-style: normal;
      margin-top: -16px;
    }
    .num-container {
      margin-top: 15px;
      & > .ant-col {
        width: 32px;
        height: 32px;
        background: #ffffff;
        border-radius: 3px;
        filter: blur(0px);
        font-family: DIN, DIN;
        font-weight: 500;
        font-size: 16px;
        color: #ae6363;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(9n) {
          color: #d7a949;
        }
        &:nth-child(9n + 1) {
          color: #90ae63;
        }
        &:nth-child(9n + 2) {
          color: #dc4388;
        }
        &:nth-child(9n + 3) {
          color: #7e51b9;
        }
        &:nth-child(9n + 4) {
          color: #63a0ae;
        }
        &:nth-child(9n + 5) {
          color: #7e51b9;
        }
        &:nth-child(9n + 6) {
          color: #d4681c;
        }
        &:nth-child(9n + 7) {
          color: #63ae79;
        }
        &:nth-child(9n + 8) {
          color: #54ade8;
        }
      }
    }
  }
  .cur-right {
    width: 342px;
    height: 188px;
    background: #f5f5f5;
    border-radius: 2px;
    padding-top: 14px;
    .font1 {
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      font-style: normal;
      .orange {
        color: #ff641e;
        &.js30 {
          color: $js30-color;
        }
        &.xy60 {
          color: $xy60-color;
        }
        &.kx90 {
          color: $kx90-color;
        }
        &.sk120 {
          color: $sk120-color;
        }
        &.jnd210 {
          color: $jnd210-color;
        }
        &.tw300 {
          color: $tw300-color;
        }
      }
    }
    .time-box {
      margin-top: 30px;
      .num {
        width: 44px;
        height: 58px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: DIN, DIN;
        font-weight: 500;
        font-size: 36px;
        color: #ffffff;
        line-height: 36px;
        margin: 0 2px;
        background-color: #ff641e;
        &.js30 {
          background-color: $js30-color;
        }
        &.xy60 {
          background-color: $xy60-color;
        }
        &.kx90 {
          background-color: $kx90-color;
        }
        &.sk120 {
          background-color: $sk120-color;
        }
        &.jnd210 {
          background-color: $jnd210-color;
        }
        &.tw300 {
          background-color: $tw300-color;
        }
      }
      .minus {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 26px;
        color: #3a3a3a;
        line-height: 37px;
        font-style: normal;
      }
    }
    .tip {
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 18px;
      color: #ff641e;
      line-height: 25px;
      text-align: center;
      font-style: normal;
      margin-top: 22px;
      &.js30 {
        color: $js30-color;
      }
      &.xy60 {
        color: $xy60-color;
      }
      &.kx90 {
        color: $kx90-color;
      }
      &.sk120 {
        color: $sk120-color;
      }
      &.jnd210 {
        color: $jnd210-color;
      }
      &.tw300 {
        color: $tw300-color;
      }
      span {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 22px;
          height: 22px;
          background: url(../../assets/luckGame/fire-icon.png) no-repeat;
          background-size: 100% 100%;
          top: 2px;
          left: -29px;
        }
        &::after {
          content: '';
          position: absolute;
          width: 22px;
          height: 22px;
          background: url(../../assets/luckGame/fire-icon.png) no-repeat;
          background-size: 100% 100%;
          top: 2px;
          right: -29px;
        }
      }
    }
  }
  .menu-box {
    margin-top: 10px;
    & > .ant-col {
      width: 180px;
      height: 48px;
      background: #f5f5f5;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #777777;
      font-style: normal;
      position: relative;
      cursor: pointer;
      &:hover {
        &.js30 {
          color: $js30-color;
          background: $js30-color-trans;
          border-color: $js30-color;
        }
        &.xy60 {
          color: $xy60-color;
          border-color: $xy60-color;
          background: $xy60-color-trans;
        }
        &.kx90 {
          color: $kx90-color;
          border-color: $kx90-color;
          background: $kx90-color-trans;
        }
        &.sk120 {
          color: $sk120-color;
          border-color: $sk120-color;
          background: $sk120-color-trans;
        }
        &.jnd210 {
          color: $jnd210-color;
          border-color: $jnd210-color;
          background: $jnd210-color-trans;
        }
        &.tw300 {
          color: $tw300-color;
          border-color: $tw300-color;
          background: $tw300-color-trans;
        }
      }
      &.current {
        color: #fff !important;
        a {
          color: #fff;
        }
        &.js30 {
          background: $js30-color;
          border-color: $js30-color;
        }
        &.xy60 {
          background: $xy60-color;
          border-color: $xy60-color;
        }
        &.kx90 {
          background: $kx90-color;
          border-color: $kx90-color;
        }
        &.sk120 {
          background: $sk120-color;
          border-color: $sk120-color;
        }
        &.jnd210 {
          background: $jnd210-color;
          border-color: $jnd210-color;
        }
        &.tw300 {
          background: $tw300-color;
          border-color: $tw300-color;
        }
      }
      .status {
        position: absolute;
        width: 42px;
        height: 18px;
        background: linear-gradient(270deg, #4ec681 0%, #1aa254 100%);
        border-radius: 0px 0px 0px 7px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 10px;
        color: #ffffff;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
      }
    }
  }
  .luck-table {
    margin-top: 10px;
    th.ant-table-cell {
      background: #e6e6e6 !important;
      height: 50px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
      color: #333333 !important;
      font-style: normal;
      text-align: center !important;
      padding: 10px !important;
      &:first-child {
        border-radius: 0 !important;
      }
      &:last-child {
        border-radius: 0 !important;
      }
      &::before {
        display: none;
      }
    }
    td.ant-table-cell {
      height: 50px;
      border-color: #d9d9d9;
      text-align: center;
      border-right: 1px solid #d9d9d9;
      padding: 10px !important;
      &:last-child {
        border-right: 0;
      }
      .blue-btn {
        cursor: pointer;
        color: #3399fe;
      }
      .table-num {
        font-family: DINAlternate, DINAlternate;
        font-weight: bold;
        font-size: 24px;
        color: #ff641e;
        line-height: 35px;
        font-style: normal;
        display: inline-block;
        width: 30px;
        text-align: center;
        vertical-align: middle;
        &.js30 {
          color: $js30-color;
        }
        &.xy60 {
          color: $xy60-color;
        }
        &.kx90 {
          color: $kx90-color;
        }
        &.sk120 {
          color: $sk120-color;
        }
        &.jnd210 {
          color: $jnd210-color;
        }
        &.tw300 {
          color: $tw300-color;
        }
      }
      .table-minus {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 12px;
        color: #333333;
        line-height: 17px;
        font-style: normal;
        vertical-align: middle;
      }
      .table-result {
        width: 28px;
        height: 28px;
        background-image: radial-gradient(
          0% 88% at 50% 11%,
          #ffb694 0%,
          #ff641e 100%
        );
        font-family: DINAlternate, DINAlternate;
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        line-height: 28px;
        text-align: center;
        font-style: normal;
        display: inline-block;
        border-radius: 100%;
        vertical-align: middle;
        margin-left: 5px;
        &.js30 {
          background: $js30-color;
        }
        &.xy60 {
          background: $xy60-color;
        }
        &.kx90 {
          background: $kx90-color;
        }
        &.sk120 {
          background: $sk120-color;
        }
        &.jnd210 {
          background: $jnd210-color;
        }
        &.tw300 {
          background: $tw300-color;
        }
      }
      .table-check {
        display: inline-block;
        width: 44px;
        height: 22px;
        background: #ff641e;
        border-radius: 2px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        margin-left: 12px;
        cursor: pointer;
        &.js30 {
          background: $js30-color;
        }
        &.xy60 {
          background: $xy60-color;
        }
        &.kx90 {
          background: $kx90-color;
        }
        &.sk120 {
          background: $sk120-color;
        }
        &.jnd210 {
          background: $jnd210-color;
        }
        &.tw300 {
          background: $tw300-color;
        }
      }
      .red {
        color: red;
      }
      .green {
        color: #129c4d;
      }
      .finish {
        width: 84px;
        height: 28px;
        background: #e6e6e6;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        font-style: normal;
        margin: 0 auto;
      }
      .bet-btn {
        width: 84px;
        height: 28px;
        background: #ff641e;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        font-style: normal;
        margin: 0 auto;
        cursor: pointer;
        &.js30 {
          background: $js30-color;
        }
        &.xy60 {
          background: $xy60-color;
        }
        &.kx90 {
          background: $kx90-color;
        }
        &.sk120 {
          background: $sk120-color;
        }
        &.jnd210 {
          background: $jnd210-color;
        }
        &.tw300 {
          background: $tw300-color;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    td.ant-table-cell-row-hover {
      background: #f8f4f1 !important;
    }
  }
  .luck-table1 {
    margin-top: 27px;
    th.ant-table-cell {
      height: 50px;
      background: #e6e6e6 !important;
      padding: 0 !important;
      text-align: center !important;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
      color: #333333 !important;
      font-style: normal;
      border-radius: 0 !important;
    }
    td.ant-table-cell {
      height: 50px;
      padding: 0 !important;
      border-bottom: 1px solid #d9d9d9;
      text-align: center !important;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      font-style: normal;
      .blue-btn {
        cursor: pointer;
        color: #3399fe;
        padding: 0 10px;
      }
      .red-btn {
        cursor: pointer;
        color: #ff3030;
      }
    }
    td.ant-table-cell-row-hover {
      background: #f8f4f1 !important;
    }
  }
  .luck-page {
    text-align: center;
    margin: 24px 0;
    .ant-pagination-options {
      display: none;
    }
    &.js30 {
      .ant-pagination-item-active {
        border-color: $js30-color;
        color: $js30-color !important;
        a {
          color: $js30-color;
        }
        &:hover a {
          color: $js30-color;
        }
      }
    }
    &.xy60 {
      .ant-pagination-item-active {
        border-color: $xy60-color;
        color: $xy60-color !important;
        a {
          color: $xy60-color;
        }
        &:hover a {
          color: $xy60-color;
        }
      }
    }
    &.kx90 {
      .ant-pagination-item-active {
        border-color: $kx90-color;
        color: $kx90-color !important;
        a {
          color: $kx90-color;
        }
        &:hover a {
          color: $kx90-color;
        }
      }
    }
    &.sk120 {
      .ant-pagination-item-active {
        border-color: $sk120-color;
        color: $sk120-color !important;
        a {
          color: $sk120-color;
        }
        &:hover a {
          color: $sk120-color;
        }
      }
    }
    &.jnd210 {
      .ant-pagination-item-active {
        border-color: $jnd210-color;
        color: $jnd210-color !important;
        a {
          color: $jnd210-color;
        }
        &:hover a {
          color: $jnd210-color;
        }
      }
      &.tw300 {
        .ant-pagination-item-active {
          border-color: $tw300-color;
          color: $tw300-color !important;
          a {
            color: $tw300-color;
          }
          &:hover a {
            color: $tw300-color;
          }
        }
      }
    }
  }
}

.luckGame-modal {
  .ant-modal-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .modal-title {
    width: 245px;
    height: 40px;
    background: url(../../assets/luckGame/modal-title.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    font-style: normal;
    margin: 20px auto 0;
  }
  .check-box {
    width: 910px;
    min-height: 425px;
    border: 1px solid #d9d9d9;
    margin: 24px auto;
    .tabel-title {
      height: 50px;
      background: #e6e6e6;
      border-bottom: 1px solid #d9d9d9;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: center;
      font-style: normal;
      line-height: 50px;
      span {
        color: #ff641e;
      }
      &.js30 {
        span {
          color: $js30-color;
        }
      }
      &.xy60 {
        span {
          color: $xy60-color;
        }
      }
      &.kx90 {
        span {
          color: $kx90-color;
        }
      }
      &.sk120 {
        span {
          color: $sk120-color;
        }
      }
      &.jnd210 {
        span {
          color: $jnd210-color;
        }
      }
      &.tw300 {
        span {
          color: $tw300-color;
        }
      }
    }
    .table-row1,
    .table-row2,
    .table-row3,
    .table-row4 {
      border-bottom: 1px solid #d9d9d9;
      & > .ant-col {
        width: 39px;
        border-right: 1px solid #d9d9d9;
        text-align: center;
        height: 45px;
        font-family: DIN, DIN;
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 45px;
        text-align: center;
        font-style: normal;
        .light {
          color: #ff641e;
          &.js30 {
            color: $js30-color;
          }
          &.xy60 {
            color: $xy60-color;
          }
          &.kx90 {
            color: $kx90-color;
          }
          &.sk120 {
            color: $sk120-color;
          }
          &.jnd210 {
            color: $jnd210-color;
          }
          &.tw300 {
            color: $tw300-color;
          }
        }
        &:last-child {
          border: none;
        }
        &:first-child {
          width: 127px;
        }
      }
    }
    .table-row2 {
      & > .ant-col {
        width: 760px;
        &:last-child {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          font-style: normal;
        }
      }
    }
    .table-row3 {
      & > .ant-col {
        width: 43px;
      }
    }
    .table-row4 {
      & > .ant-col {
        width: 258px;
      }
    }
    .num-box {
      margin-top: 12px;
      .num-bg1,
      .num-bg2,
      .num-bg3,
      .num-bg4 {
        width: 64px;
        height: 58px;
        background: url(../../assets/luckGame/num-bg1.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        font-family: DIN, DIN;
        font-weight: bold;
        font-size: 26px;
        line-height: 38px;
        color: #ffffff;
        font-style: normal;
        padding: 5px;
      }
      .num-bg2 {
        background: url(../../assets/luckGame/num-bg2.png) no-repeat;
        background-size: 100% 100%;
      }
      .num-bg3 {
        background: url(../../assets/luckGame/num-bg3.png) no-repeat;
        background-size: 100% 100%;
      }
      .num-bg4 {
        background: url(../../assets/luckGame/num-bg4.png) no-repeat;
        background-size: 100% 100%;
      }
      .minus-icon {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 26px;
        color: #999999;
        line-height: 37px;
        font-style: normal;
        margin-top: -16px;
      }
    }
  }
  .bet-mode-title {
    text-align: center;
    position: relative;
    margin: 29px 24px 0;
    &::before {
      content: '';
      position: absolute;
      width: 910px;
      height: 1px;
      background-color: #d9d9d9;
      top: 50%;
      left: 0;
    }
    & > span {
      padding: 6px 17px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #333333;
      line-height: 17px;
      font-style: normal;
      background-color: #fff;
      z-index: 2;
      position: relative;
      .orange {
        color: #ff641e;
        &.js30 {
          color: $js30-color;
        }
        &.xy60 {
          color: $xy60-color;
        }
        &.kx90 {
          color: $kx90-color;
        }
        &.sk120 {
          color: $sk120-color;
        }
        &.jnd210 {
          color: $jnd210-color;
        }
        &.tw300 {
          color: $tw300-color;
        }
      }
    }
  }
  .mode-list {
    padding: 24px;
    flex-wrap: wrap;
    & > .ant-col {
      min-width: 84px;
      height: 30px;
      background: #e6e6e6;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #333333;
      font-style: normal;
      margin: 0 6px;
      cursor: pointer;
      margin-bottom: 12px;
    }
  }
  .delete-tip {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    margin-top: 31px;
  }
  .bottom-btns {
    margin-top: 46px;
    margin-bottom: 29px;
    & > .ant-col {
      width: 120px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #999999;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      font-style: normal;
      margin: 0 19px;
      cursor: pointer;
      &.orange {
        background-color: #ff641e;
        border-color: #ff641e;
        color: #fff;
      }
    }
  }
  .num-detail {
    margin-top: 18px;
    & > .ant-col {
      margin: 0 4px;
      width: 84px;
      height: 60px;
      background: #f5f5f5;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      margin-bottom: 10px;
      & > div {
        text-align: center;
        line-height: 29px;
        &:first-child {
          height: 29px;
          background: #e6e6e6;
          border-bottom: 1px solid #d9d9d9;
        }
      }
      &.orange {
        background: #ffa376;
        height: 150px;
        color: #fff;
        & > div {
          border-bottom: 1px solid #d9d9d9;
          &:first-child {
            background: #ff641e;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
        &.js30 {
          background: $js30-color-trans5;
          & > div {
            &:first-child {
              background: $js30-color;
            }
          }
        }
        &.xy60 {
          background: $xy60-color-trans5;
          & > div {
            &:first-child {
              background: $xy60-color;
            }
          }
        }
        &.kx90 {
          background: $kx90-color-trans5;
          & > div {
            &:first-child {
              background: $kx90-color;
            }
          }
        }
        &.sk120 {
          background: $sk120-color-trans5;
          & > div {
            &:first-child {
              background: $sk120-color;
            }
          }
        }
        &.jnd210 {
          background: $jnd210-color-trans5;
          & > div {
            &:first-child {
              background: $jnd210-color;
            }
          }
        }
        &.tw300 {
          background: $tw300-color-trans5;
          & > div {
            &:first-child {
              background: $tw300-color;
            }
          }
        }
      }
    }
  }
  .bottom-tip {
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 17px;
    font-style: normal;
    margin-top: 9px;
    margin-bottom: 18px;
    span {
      vertical-align: middle;
      &.red {
        color: #ff641e;
        vertical-align: middle;
      }
      &.green {
        color: #129c4d;
      }
    }
    &.js30 {
      span {
        color: $js30-color;
      }
    }
    &.xy60 {
      span {
        color: $xy60-color;
      }
    }
    &.kx90 {
      span {
        color: $kx90-color;
      }
    }
    &.sk120 {
      span {
        color: $sk120-color;
      }
    }
    &.jnd210 {
      span {
        color: $jnd210-color;
      }
    }
    &.tw300 {
      span {
        color: $tw300-color;
      }
    }
  }
  .mode-input {
    margin: 12px 0 0 24px;
    width: 910px;
  }
}
