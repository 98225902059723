.rank-container {
  background-color: #faf7f5;
  .banner-contaner {
    width: 100%;
    height: 260px;
    position: relative;
    background-color: #f5c07d;
    .banner-img {
      width: 100%;
      height: 260px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
  .rank-box {
    width: 1200px;
    margin: 20px auto 0;
    & > .ant-col {
      width: 590px;
      background: #ffffff;
      box-shadow: 0px 2px 16px 0px rgba(150, 88, 60, 0.1);
      border-radius: 6px;
      .rank-title {
        height: 90px;
        background: url('../../assets/rank/title1.png') no-repeat;
        font-size: 0;
        background-size: contain;
      }
      .rank-list-box {
        padding: 20px 20px 0 20px;
        th.ant-table-cell {
          height: 50px;
          background: linear-gradient(180deg, #ffdecf 0%, #d74e0f 100%);
          border-radius: 0 !important;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #ffffff;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          padding-left: 28px;
          &::before {
            display: none;
          }
        }
        tr.ant-table-row {
          &:nth-of-type(odd) {
            background-color: #fff2eb;
          }
          &:nth-of-type(even) {
            background-color: #ffe3d7;
          }
          td.ant-table-cell {
            height: 64px;
            border: 0;
            .rank-1,
            .rank-2,
            .rank-3 {
              width: 31px;
              height: 34px;
              background: url(../../assets/rank/rank1.png) no-repeat;
              background-size: contain;
            }
            .rank-2 {
              background: url(../../assets/rank/rank2.png) no-repeat;
              background-size: contain;
            }
            .rank-3 {
              background: url(../../assets/rank/rank3.png) no-repeat;
              background-size: contain;
            }
            .rank-num {
              width: 26px;
              height: 26px;
              background: #fdc0a6;
              border: 1px solid #ea8e67;
              font-size: 16px;
              color: #ffffff;
              font-style: normal;
              border-radius: 100%;
              display: flex;
              justify-items: center;
              justify-content: center;
              margin-left: 3px;
            }
            &:first-child {
              padding-left: 25px;
            }
            .avatar-box {
              margin-right: 7px;
            }
            .uname {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #333333;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
            .gold {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #ff641e;
              line-height: 14px;
              text-align: left;
              font-style: normal;
            }
            .coin-icon {
              width: 13px;
              height: 13px;
              margin-left: 5px;
            }
          }
        }
      }

      &.yesterday {
        .rank-title {
          background: url(../../assets/rank/title2.png) no-repeat;
          background-size: contain;
        }
        .rank-list-box {
          padding: 20px 20px 0 20px;
          th.ant-table-cell {
            background: linear-gradient(180deg, #ffedc9 0%, #c68400 100%);
          }
          tr.ant-table-row {
            &:nth-of-type(odd) {
              background-color: #fff8eb;
            }
            &:nth-of-type(even) {
              background-color: #faecd1;
            }
          }
        }
      }
    }
  }
  .rule-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    width: 1200px;
    margin: 30px auto 0;
  }
  .rule-list {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    padding-bottom: 55px;
    width: 1200px;
    margin: 10px auto 0;
  }
}
