.hbao-container {
  background-color: #faf7f5;
  padding: 18px 0 40px;
  .empty-box {
    margin-top: 200px;
  }
  .hbao-main {
    width: 1200px;
    margin: 0 auto 0;
    .left-nav {
      width: 250px;
      height: 807px;
      background: #ffffff;
      box-shadow: 0px 2px 16px 0px rgba(150, 88, 60, 0.1);
      border-radius: 6px;
      .nav-menu {
        .title {
          text-align: center;
          font-size: 24px;
          color: #fff;
          line-height: 62px;
          margin-bottom: 24px;
          height: 62px;
        }
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ff641e;
        line-height: 14px;
        font-style: normal;
        background: url(../../assets/hbao/menu-bg.png) top left no-repeat;
        background-size: 100% auto;
        & > .ant-row {
          height: 62px;
          cursor: pointer;
          margin-bottom: 12px;
          padding-left: 68px;
          & > .ant-col:first-child {
            font-size: 0;
            height: 23px;
            width: 23px;
            margin-right: 10px;
          }
          &.current {
            color: #fff;
            background: #ff641e;
          }
          .font-icon {
            font-size: 22px;
          }
        }
        .nav-icon {
          width: 23px;
          height: 23px;
        }
      }
    }
    .right-main {
      width: 932px;
      background: #ffffff;
      box-shadow: 0px 2px 16px 0px rgba(150, 88, 60, 0.1);
      border-radius: 6px;
      padding-bottom: 20px;
      .rule-box {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333;
        line-height: 30px;
        text-align: left;
        font-style: normal;
        padding: 0 20px 0 40px;
        margin-top: 14px;
        border: none;
        & > li {
          margin-bottom: 10px;
        }
      }
      .coin-icon {
        width: 12px;
        height: auto;
        margin-left: 2px;
      }
      .orange-title {
        height: 48px;
        background: linear-gradient(180deg, #ff9d40 0%, #ff641e 100%);
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        line-height: 48px;
        text-align: left;
        font-style: normal;
        padding-left: 19px;
        padding-right: 29px;
        border-radius: 6px 6px 0 0;
        .header-col {
          margin-left: 36px;
          .icon1 {
            width: 13px;
            height: 14px;
          }
          .icon2 {
            width: 12px;
            height: 12px;
          }
          .icon3 {
            width: 18px;
            height: 18px;
          }
          img {
            margin-right: 7px;
            vertical-align: middle;
          }
          span {
            vertical-align: middle;
          }
        }
      }
      .search-box {
        margin: 4px 30px 0 30px;
        &.nav1 {
          margin-top: 20px;
        }
        &.nav2 {
          margin-left: 0;
          margin-top: 0;
          .tab-item {
            width: 208px;
            height: 48px;
            background: linear-gradient(180deg, #c9c9c9 0%, #dadada 100%);
            border-radius: 6px 6px 0px 0px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            cursor: pointer;
            &.current {
              background: linear-gradient(180deg, #ff9d40 0%, #ff641e 100%);
            }
          }
          .apply_btn {
            width: 102px;
            height: 40px;
            border-radius: 2px;
            border: 1px solid #1e92ff;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #1e92ff;
            font-style: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            cursor: pointer;
          }
        }
        .search-input {
          width: 323px;
          height: 40px;
        }
        .ant-input-affix-wrapper {
          padding: 8px 11px;
          border-radius: 20px 0 0 20px;
          border: 1px solid #ff641e;
        }
        .search-icon {
          width: 19px;
          height: 20px;
        }
        .ant-input-search-button {
          width: 92px;
          height: 40px;
          background: #ff641e;
          border-radius: 20px !important;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          font-style: normal;
          z-index: 10;
        }
        .ant-input-group-addon:last-child {
          left: -23px;
        }
        .sort-col {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          font-style: normal;
          display: flex;
          align-items: center;
          margin-right: 18px;
          cursor: pointer;
          .sort-icon {
            font-size: 14px;
          }
          .orange {
            color: #ff641e;
          }
        }
      }
      .add-btn {
        width: 102px;
        height: 40px;
        background: #faa624;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        font-style: normal;
        cursor: pointer;
        .add-icon {
          width: 18px;
          height: 18px;
          vertical-align: middle;
          margin-right: 4px;
        }
      }
      .key-btn {
        width: 105px;
        height: 40px;
        background: #1e92ff;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        font-style: normal;
        cursor: pointer;
        margin-left: 17px;
        .key-icon {
          margin-right: 4px;
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }
      .list-box {
        margin: 23px 0 0 30px;
        & > .ant-col {
          width: 284px;
          height: 228px;
          background: #faf7f5;
          border-radius: 4px;
          margin-right: 10px;
          margin-bottom: 10px;
          .list-header {
            height: 36px;
            background-color: #ff641e;
            padding: 0 12px;
            border-radius: 4px 4px 0 0;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            font-style: normal;
            &.yellow {
              background-color: #ffd390;
            }
            .safe-icon {
              width: 14px;
              height: 16px;
              vertical-align: middle;
              margin-right: 10px;
            }
            .key-icon {
              width: 17px;
              height: 17px;
              vertical-align: middle;
            }
            .header-tip {
              width: 63px;
              height: 36px;
              background: #ffd78d;
              border-radius: 4px 20px 0px 0px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #ff641e;
              font-style: normal;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-left: -12px;
              &.blue {
                background-color: #bcdeff;
                color: #1e92ff;
              }
            }
            .set-icon {
              width: 17px;
              height: 17px;
              margin-left: 8px;
              vertical-align: middle;
              cursor: pointer;
            }
            .out-icon {
              width: 16px;
              height: 16px;
              margin-left: 8px;
              vertical-align: middle;
              cursor: pointer;
            }
          }
          .middle-box {
            padding: 22px 8px 0 18px;
            & > .ant-col {
              width: 170px;
              .font1 {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #333333;
                line-height: 20px;
                text-align: left;
                font-style: normal;
                width: 130px;
                margin-bottom: 13px;
              }
              .font2 {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                line-height: 20px;
                text-align: left;
                font-style: normal;
                margin-bottom: 5px;
                img {
                  width: 14px;
                  height: 14px;
                  margin-right: 6px;
                  vertical-align: middle;
                }
              }
              .font3 {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                line-height: 20px;
                text-align: left;
                font-style: normal;
                img {
                  width: 12px;
                  height: 12px;
                  vertical-align: middle;
                  margin-right: 6px;
                }
              }
            }
            .list-icon {
              width: 83px;
              height: 111px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .myApply-date {
            height: 20px;
            background: #e8e8e8;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            padding-left: 27px;
          }
          .bottom-box {
            padding: 0 12px 0 18px;
            margin-top: 10px;
            .btn {
              width: 80px;
              height: 30px;
              background: linear-gradient(180deg, #8dc8ff 0%, #1e92ff 100%);
              border-radius: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #ffffff;
              font-style: normal;
              cursor: pointer;
              &.orange {
                background: linear-gradient(180deg, #ffb694 0%, #ff641e 100%);
              }
              &.yellow {
                background: linear-gradient(180deg, #ffb01e 0%, #ffb01e 100%);
              }
              &.cancel {
                width: 74px;
                height: 24px;
                border-radius: 2px;
                border: 1px solid #1e92ff;
                background: #fff;
                color: #1e92ff;
              }
            }
            .font4 {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #999999;
              text-align: left;
              font-style: normal;
              .set-btn {
                color: #1e92ff;
                margin-right: 14px;
                cursor: pointer;
              }
              .cancel-btn {
                color: #fe3333;
                cursor: pointer;
              }
              .ant-col {
                font-size: 12px;
              }
              & > .ant-col {
                img {
                  width: 14px;
                  height: 13px;
                  margin-right: 3px;
                  vertical-align: middle;
                  margin-left: 11px;
                }
                span {
                  vertical-align: middle;
                }
              }
            }
            .font5 {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #faa624;
              line-height: 17px;
              text-align: left;
              font-style: normal;
              padding-left: 9px;
            }
          }
        }
      }
      .ant-pagination {
        text-align: center;
        .ant-pagination-options {
          display: none;
        }
      }
      .date-search {
        margin: 30px 30px 0 30px;
        .ant-picker,
        .ant-input,
        .ant-select {
          padding: 6px 11px;
          border-radius: 2px;
        }
        .ant-select {
          .ant-select-selector {
            border-radius: 0;
            height: 36px;
          }
          .ant-select-arrow {
            margin-right: 10px;
          }
        }
        .ant-col.first {
          margin-right: 10px;
        }
        .search-btn {
          width: 68px;
          height: 38px;
          background: #ff641e;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          font-style: normal;
          margin-left: 18px;
          cursor: pointer;
        }
      }
      .hbao-table {
        margin: 19px 30px;
        th.ant-table-cell {
          background-color: #f5f5f5;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #333333;
          font-style: normal;
          height: 50px;
          border-radius: 0 !important;
          white-space: nowrap;
        }
        td.ant-table-cell {
          height: 50px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          font-style: normal;
          .table-btn {
            width: 80px;
            height: 30px;
            background: linear-gradient(180deg, #ffb694 0%, #ff641e 100%);
            border-radius: 3px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.disabled {
              background-color: gray;
              cursor: not-allowed;
            }
          }
          .orange {
            color: #ff641e;
          }
          &.nowrap {
            white-space: nowrap;
          }
          .green {
            color: #129c4d;
          }
          .check-btn {
            width: 44px;
            height: 22px;
            background: #ff641e;
            border-radius: 2px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 12px;
            font-style: normal;
            margin-left: 5px;
            cursor: pointer;
          }
        }
        tr:nth-of-type(even) {
          td.ant-table-cell {
            background-color: #f5f5f5;
          }
        }
      }
      .game-main {
        padding: 18px 30px 48px;
        .hbao-box {
          width: 431px;
          height: 1000px;
          overflow-y: auto;
          background: url(../../assets/hbao/hbao-bg.png) 0 0 no-repeat;
          padding: 7px 12px 53px;
          border-bottom: 1px solid #f4dec8;
          .history-tip {
            text-align: center;
            color: #ff641e;
            margin-top: 20px;
            span {
              &:hover {
                text-decoration: underline;
              }
              cursor: pointer;
            }
          }
          .time {
            text-align: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #888888;
            line-height: 12px;
            font-style: normal;
            margin-top: 38px;
          }
          .tip {
            text-align: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 12px;
            color: #666;
            line-height: 23px;
            font-style: normal;
            margin-top: 18px;
            .orange {
              color: #ba6b3e;
            }
          }
          .hbao-item {
            margin-top: 24px;
            &.mine {
              flex-flow: row-reverse;
              .font1 {
                text-align: right;
              }
            }
            & > .ant-col:first-child {
              margin-right: 8px;
            }
            .font1 {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #333333;
              line-height: 14px;
              text-align: left;
              font-style: normal;
              margin-top: 10px;
              &.right {
                text-align: right;
              }
              .gray {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #888888;
                line-height: 12px;
                font-style: normal;
                vertical-align: middle;
                padding: 0 5px;
              }
            }
            .hbao-item-bg {
              width: 290px;
              height: 142px;
              background: url(../../assets/hbao/hbao.png) no-repeat;
              padding: 38px 0 0 22px;
              margin-top: 8px;
              cursor: pointer;
              .coin-icon {
                width: 12px;
                height: auto;
                margin-left: 5px;
              }
              &.end {
                background: url(../../assets/hbao/hbao-end.png) no-repeat;
              }
              &.get {
                background: url(../../assets/hbao/hbao-get.png) no-repeat;
              }
              &.timeout {
                background: url(../../assets/hbao/hbao-timeout.png) no-repeat;
              }
              ul {
                list-style: none;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #ffe2c1;
                line-height: 22px;
                text-align: left;
                font-style: normal;
              }
              .font2 {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #ffc39f;
                line-height: 17px;
                text-align: left;
                font-style: normal;
                margin-top: 13px;
              }
            }
          }
        }
        .hbao-data {
          width: 431px;
          .hbao-auto-set {
            width: 431px;
            height: 60px;
            background: #faf7f5;
            border-radius: 2px;
            border: 1px solid #f4dec8;
            padding: 0 18px;
            .how-set-icon {
              vertical-align: middle;
              width: 18px;
              height: 18px;
              margin-left: 5px;
            }
            .tip {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #daba99;
              line-height: 20px;
              text-align: left;
              font-style: normal;
              padding-left: 4px;
              &.orange {
                color: #ff641e;
              }
            }
          }
          .today-data {
            width: 431px;
            height: 182px;
            background: #faf7f5;
            border-radius: 2px;
            border: 1px solid #f4dec8;
            margin-top: 10px;
            padding: 22px 18px 0;
            .title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #ff641e;
              line-height: 16px;
              text-align: left;
              font-style: normal;
              .font2 {
                color: #333;
                font-size: 12px;
                padding-left: 24px;
                .orange {
                  color: #ff641e;
                }
                .green {
                  color: #17a955;
                }
              }
            }
            .today-item {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              line-height: 16px;
              text-align: left;
              font-style: normal;
              margin-top: 24px;
              & > .ant-col {
                width: 50%;
              }
              .orange {
                color: #ff641e;
              }
              .green {
                color: #129c4d;
              }
            }
          }
          .tab-box {
            width: 431px;
            background: #faf7f5;
            border-radius: 2px;
            border: 1px solid #f4dec8;
            margin-top: 10px;
            padding-bottom: 10px;
            .tab-items {
              height: 40px;
              background: #fbeddf;
              border-radius: 2px 2px 0px 0px;
              border: 1px solid #f4dec8;
              & > .ant-col {
                width: 120px;
                text-align: center;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #ff641e;
                line-height: 20px;
                font-style: normal;
                height: 41px;
                line-height: 41px;
                cursor: pointer;
                &.current {
                  background-color: #faf7f5;
                }
                &:first-child {
                  &.current {
                    position: relative;
                    &::after {
                      content: '';
                      width: 1px;
                      height: 40px;
                      background-color: #f4dec8;
                      top: -1px;
                      right: 0;
                      position: absolute;
                    }
                  }
                }
                &:last-child {
                  &.current {
                    position: relative;
                    &::after {
                      content: '';
                      width: 1px;
                      height: 40px;
                      background-color: #f4dec8;
                      top: -1px;
                      right: 0;
                      position: absolute;
                    }
                    &::before {
                      content: '';
                      width: 1px;
                      height: 40px;
                      background-color: #f4dec8;
                      top: -1px;
                      left: 0;
                      position: absolute;
                    }
                  }
                }
              }
            }
            .hbao-tab-table {
              margin-bottom: 10px;
              th.ant-table-cell {
                height: 45px;
                background-color: #faf7f5;
                border-color: #f4dec8;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 14px;
                color: #666666;
                line-height: 20px;
                font-style: normal;
                padding: 0;
                &:first-child {
                  padding-left: 5px;
                }
                &::before {
                  display: none;
                }
              }
              td.ant-table-cell {
                height: 45px;
                background-color: #faf7f5;
                border-color: #f4dec8;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                line-height: 20px;
                font-style: normal;
                padding: 0;
                &:first-child {
                  padding-left: 5px;
                }
                .red {
                  color: #ff641e;
                }
                .green {
                  color: #129c4d;
                }
              }
            }
          }
          .send-btn {
            width: 150px;
            height: 48px;
            background: #ef2517;
            border-radius: 2px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 13px 0 0 281px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

// 新弹框
.hbao-modal {
  .ant-modal-content {
    padding: 0 !important;
    padding-bottom: 25px !important;
    .modal-title {
      height: 60px;
      background: linear-gradient(180deg, #ff9d40 0%, #ff641e 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      font-style: normal;
      border-radius: 8px 8px 0 0;
    }
    .modal-form {
      margin-top: 34px;
      &.pwd {
        margin-top: 14px;
      }
      & > .ant-col {
        &:first-child {
          width: 120px;
          text-align: right;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          font-style: normal;
        }
        .modal-input {
          width: 295px;
          padding: 4px 5px;
        }
        .modal-level {
          width: 40px;
          margin: 0 10px;
        }
        .modal-num {
          margin-top: -8px;
          width: 345px;
          & > .ant-col {
            width: 107px;
            height: 36px;
            border-radius: 2px;
            border: 1px solid #cccccc;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            font-style: normal;
            margin-right: 8px;
            cursor: pointer;
            margin-bottom: 8px;
            &.current {
              background: rgba(255, 100, 30, 0.1);
              border: 1px solid #ff641e;
              color: #ff641e;
            }
          }
        }
      }
      .modal-num-tip {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #cccccc;
        line-height: 17px;
        text-align: left;
        font-style: normal;
        margin-top: 54px;
      }
      .modal-rule {
        font-family: PingFangSC, PingFang SC;
        font-weight: normal;
        font-size: 14px;
        color: #333333;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        .red {
          color: #ff1111;
        }
      }
    }
    .modal-btn {
      margin-top: 36px;
      & > .ant-col {
        margin: 0 18px;
      }
      .orange-btn {
        width: 120px;
        height: 40px;
        background: #ff641e;
        border-radius: 4px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .gray-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #b3b3b3;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .modal-add-num {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        font-style: normal;
      }
    }
    .modal-icon-box {
      position: absolute;
      top: 87px;
      right: 55px;
      .icon-img {
        width: 109px;
        height: 123px;
        background: #efeeec;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 9px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .select-icon-tip {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        font-style: normal;
        cursor: pointer;
        & > .ant-col {
          margin: 0 3px;
        }
      }
      .ant-tooltip-inner {
        background: rgba(0, 0, 0, 0.6);
      }
      .ant-tooltip-arrow::before {
        background: rgba(0, 0, 0, 0.6);
      }
      .modal-icon-img {
        & > .ant-col {
          width: 62px;
          height: 83px;
          margin: 8px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .ant-tooltip {
        width: 407px;
        max-width: initial;
        .ant-tooltip-content {
          max-width: initial;
        }
      }
    }
    .pwd-modal-tip {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: center;
      font-style: normal;
      margin-top: 26px;
    }
    .pwd-modal-subtip {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #cccccc;
      line-height: 20px;
      text-align: center;
      font-style: normal;
      margin-top: 8px;
    }
    .pwds-box {
      margin-top: 34px;
      justify-content: center;
      width: 100%;
      .ant-input {
        width: 50px;
        height: 50px;
        border-radius: 2px;
        border: 1px solid #ff641e;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        color: #ff641e;
      }
    }
    .cancel-modal-tip {
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      font-style: normal;
      margin-top: 24px;
    }
    .cancel-modal-main {
      width: 440px;
      height: 206px;
      background: #fff3e6;
      border-radius: 6px;
      border: 1px solid #eec18c;
      margin: 20px auto 0;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      font-style: normal;
      padding-top: 30px;
      & > .ant-row {
        margin-bottom: 28px;
      }
      .cancel-label {
        width: 98px;
        text-align: right;
      }
    }
    .cancel-ruler {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      line-height: 30px;
      text-align: left;
      font-style: normal;
      margin: 17px 0 0 70px;
      .red {
        color: #fe3333;
      }
    }
    .apply-search {
      margin: 36px 40px 0 40px;
      .level-select {
        .ant-select-selector {
          height: 40px;
          border-radius: 2px;
          border: 1px solid #cccccc;
          line-height: 40px;
        }
        .ant-select-selection-item {
          line-height: 40px;
        }
      }
      .oper-btns {
        & > .ant-col {
          width: 94px;
          height: 40px;
          background: #17a955;
          border-radius: 2px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          font-style: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 14px;
          cursor: pointer;
          &.red {
            background-color: #ea2a2a;
          }
        }
      }
    }
    .apply-list {
      margin: 30px 102px 28px 40px;

      .apply-info {
        margin-left: 13px;
        width: 379px;
        .font1 {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          text-align: left;
          font-style: normal;
          vertical-align: middle;
        }
        .font2 {
          width: 27px;
          height: 16px;
          background: linear-gradient(180deg, #ffca60 0%, #c68400 100%);
          border-radius: 2px;
          display: inline-block;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 16px;
          text-align: center;
          font-style: normal;
          margin-left: 6px;
          vertical-align: middle;
        }
        .font3 {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          margin-top: 8px;
        }
      }
      .list-oper-btns {
        & > .ant-col {
          width: 64px;
          height: 40px;
          border-radius: 2px;
          border: 1px solid #17a955;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #129c4d;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          margin-left: 12px;
          cursor: pointer;
          &.red {
            color: #ea2a2a;
            border-color: #ea2a2a;
          }
        }
      }
    }
    .ant-pagination {
      text-align: center;
      margin-top: 10px;
      .ant-pagination-options {
        display: none;
      }
    }
    .quit-ruler {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 14px;
      text-align: center;
      font-style: normal;
      margin-top: 18px;
    }
    .habo-modal-tip {
      text-align: right;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      font-style: normal;
      padding-right: 8px;
    }
    .modal-dl-num {
      width: 345px;
      & > .ant-col {
        width: 61px;
        height: 36px;
        border-radius: 2px;
        border: 1px solid #cccccc;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        font-style: normal;
        flex-wrap: wrap;
        margin-right: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        &.current {
          background: rgba(255, 100, 30, 0.1);
          border: 1px solid #ff641e;
          color: #ff641e;
        }
      }
    }
  }
  &.rob {
    padding: 0 !important;
    .ant-modal-content {
      padding-top: 49px !important;
      height: 840px;
      box-shadow: none;
      .hbao-result-box {
        max-height: 290px;
        overflow-y: auto;
        width: 398px;
        margin-left: 135px;
        padding-right: 10px;
      }
      .close-icon {
        cursor: pointer;
        width: 32px;
        height: 32px;
        background: url(../../assets/hbao/close-icon.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 78px;
        top: 0;
      }
      .id-font {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #ffce95;
        line-height: 22px;
        text-align: center;
        font-style: normal;
      }
      .result-font1 {
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #ffe8cd;
        line-height: 16px;
        font-style: normal;
        margin-top: 201px;
      }
      .result-font2 {
        text-align: center;
        margin-top: 24px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #f3331d;
        line-height: 22px;
        font-style: normal;
        img {
          width: 19px;
          height: 18px;
          margin-left: 4px;
          margin-right: 21px;
          vertical-align: middle;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .result-font3 {
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #f3331d;
        line-height: 20px;
        font-style: normal;
        margin-top: 45px;
        margin-bottom: 20px;
      }
      .result-item {
        margin: 0 0 11px 0;
        .result-avatar {
          margin-right: 12px;
        }
        .result-name {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          width: 119px;
        }
        .result-gold {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ff641e;
          line-height: 14px;
          text-align: left;
          font-style: normal;
          .green {
            color: #129c4d;
          }
          .coin-icon {
            width: 12px;
            height: 12px;
            margin-left: 6px;
          }
        }
        .result-time {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }
      }
    }
    &.end,
    &.timeout,
    &.mine {
      .ant-modal-content {
        background: url(../../assets/hbao/end-bg.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    &.win {
      .ant-modal-content {
        background: url(../../assets/hbao/win-bg.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    &.mine {
      .ant-modal-content {
        background: url(../../assets/hbao/mine-bg.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

// 新弹框
.ucenter-modal {
  .ant-modal-content {
    padding: 0 !important;
    padding-bottom: 36px !important;
    .modal-title {
      height: 50px;
      background: linear-gradient(180deg, #ff9d40 0%, #ff641e 100%);
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      font-style: normal;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 8px 8px 0 0;
    }
    .level-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      padding-left: 13px;
      margin: 10px 0 0 20px;
      .title-icon {
        width: 8px;
        height: 8px;
        border: 2px solid #ff641e;
        border-radius: 100%;
        margin-right: 7px;
      }
    }
    .level-rule {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666;
      line-height: 25px;
      text-align: left;
      font-style: normal;
      padding: 0 20px 0 40px;
      margin-top: 10px;
    }
    .level-info {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666;
      line-height: 30px;
      text-align: left;
      font-style: normal;
      margin: 15px 30px 0;
      color: #ff641e;
      & > .ant-col {
        margin-right: 24px;
      }
    }
    .level-table {
      margin: 10px 30px 0;
      th.ant-table-cell {
        background-color: #f5f5f5;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        font-style: normal;
        height: 50px;
        border-radius: 0 !important;
      }
      td.ant-table-cell {
        height: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        font-style: normal;
        .table-btn {
          width: 80px;
          height: 30px;
          background: linear-gradient(180deg, #ffb694 0%, #ff641e 100%);
          border-radius: 3px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.disabled {
            background-color: gray;
            cursor: not-allowed;
          }
        }
        .orange {
          color: #ff641e;
        }
        .green {
          color: #129c4d;
        }
      }
      tr:nth-of-type(even) {
        td.ant-table-cell {
          background-color: #f5f5f5;
        }
      }
    }
  }
}
