.top-login {
  height: 70px;
  background-color: rgba(255, 255, 255, 0.92);
  & > .ant-row {
    width: 1200px;
    margin: 0 auto;
    height: 70px;
    .left-part {
      .logo-icon {
        width: 120px;
        height: 44px;
      }
    }
    .right-part {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333;
      .link {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333;
        &.orange {
          color: #ff641e;
        }
      }
      .right-item {
        margin-left: 16px;
      }
    }
    .user-info {
      & > .ant-col {
        display: flex;
        align-items: center;
        margin-left: 32px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #000000;
        line-height: 17px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        &.id-box {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 22px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          .id-text {
            padding-left: 20px;
          }
          .uname-text {
            padding-left: 5px;
          }
        }
      }
      .coin-icon {
        width: 10px;
        height: auto;
      }
      .orange {
        color: #ff641e;
      }
      .top-icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .point-box {
        cursor: pointer;
      }
      .red {
        color: red;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
.ant-popover-inner-content {
  .download-img {
    width: 300px;
  }
}

.ant-modal.index-modal {
  .ant-modal-content {
    padding: 0 !important;
    padding-bottom: 30px !important;
    .modal-title {
      height: 60px;
      background: linear-gradient(180deg, #ff9d40 0%, #ff641e 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      font-style: normal;
      border-radius: 8px 8px 0 0;
    }
    .notice-title {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 18px;
      margin: 24px 40px 0;
    }
    .notice-content {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 18px;
      margin: 17px 40px 0;
    }
  }
  .index-dialog-btns {
    text-align: center;
    .ant-btn {
      margin: 30px 15px 0;
    }
  }
}
