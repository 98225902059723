$js30-color: #ff641e;
$xy60-color: #247cff;
$kx90-color: #55cc00;
$sk120-color: rgb(4, 154, 137);
$jnd210-color: #e52c2c;
$tw300-color: #ae2ce5;

.auto-container {
  .auto-type {
    margin-top: 30px;
    height: 38px;
    .ant-radio-button-wrapper {
      line-height: 38px;
      height: 38px;
      border-radius: 0;
    }
    &.js30 {
      .ant-radio-button-wrapper-checked,
      .ant-radio-button-wrapper-checked:hover {
        background: $js30-color;
        border-color: $js30-color;
        &:hover {
          color: #fff;
        }
      }
      .ant-radio-button-wrapper:hover {
        color: $js30-color;
      }
      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):hover::before,
      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        )::before {
        background-color: $js30-color;
      }
    }
    &.xy60 {
      .ant-radio-button-wrapper-checked,
      .ant-radio-button-wrapper-checked:hover {
        background: $xy60-color;
        border-color: $xy60-color;
        &:hover {
          color: #fff;
        }
      }
      .ant-radio-button-wrapper:hover {
        color: $xy60-color;
      }
      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):hover::before,
      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        )::before {
        background-color: $xy60-color;
      }
    }
    &.kx90 {
      .ant-radio-button-wrapper-checked,
      .ant-radio-button-wrapper-checked:hover {
        background: $kx90-color;
        border-color: $kx90-color;
        &:hover {
          color: #fff;
        }
      }
      .ant-radio-button-wrapper:hover {
        color: $kx90-color;
      }
      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):hover::before,
      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        )::before {
        background-color: $kx90-color;
      }
    }
    &.sk120 {
      .ant-radio-button-wrapper-checked,
      .ant-radio-button-wrapper-checked:hover {
        background: $sk120-color;
        border-color: $sk120-color;
        &:hover {
          color: #fff;
        }
      }
      .ant-radio-button-wrapper:hover {
        color: $sk120-color;
      }
      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):hover::before,
      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        )::before {
        background-color: $sk120-color;
      }
    }
    &.jnd210 {
      .ant-radio-button-wrapper-checked,
      .ant-radio-button-wrapper-checked:hover {
        background: $jnd210-color;
        border-color: $jnd210-color;
        &:hover {
          color: #fff;
        }
      }
      .ant-radio-button-wrapper:hover {
        color: $jnd210-color;
      }

      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):hover::before,
      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        )::before {
        background-color: $jnd210-color;
      }
    }
    &.tw300 {
      .ant-radio-button-wrapper-checked,
      .ant-radio-button-wrapper-checked:hover {
        background: $tw300-color;
        border-color: $tw300-color;
        &:hover {
          color: #fff;
        }
      }
      .ant-radio-button-wrapper:hover {
        color: $tw300-color;
      }

      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):hover::before,
      .css-dev-only-do-not-override-1c4x9cb.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        )::before {
        background-color: $tw300-color;
      }
    }
  }

  .auto-main {
    border-radius: 5px;
    border: 1px solid #d5d5d5;
    margin-top: 20px;
    padding-bottom: 20px;
    .auto-main-head {
      height: 68px;
      border-bottom: 1px solid #d5d5d5;
      padding-left: 26px;
      line-height: 68px;
      font-size: 15px;
      color: #080808;
    }
    .auto-main-con {
      padding: 39px 0 0 30px;
      .form-input {
        width: 200px;
      }
      .ant-form-item-label {
        width: 100px;
        text-align: left;
      }
      .gray-font {
        font-size: 15px;
        color: #747474;
        line-height: 15px;
      }
      .ant-form-item {
        margin-bottom: 14px;
      }
      .tip {
        font-size: 14px;
        color: #080808;
        padding-left: 34px;
        padding-top: 5px;
      }
    }
    .select-ops {
      .ant-col {
        margin-right: 46px;
        cursor: pointer;
      }
      img {
        width: 25px;
        height: 25px;
        vertical-align: middle;
      }
    }
    .rang-num {
      .ant-col {
        font-size: 14px;
        margin-right: 41px;
        span {
          display: inline-block;
          width: 100px;
        }
      }
    }
  }
  .auto-btn {
    width: 201px;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #ffffff;
    margin: 50px auto 0;
    background-image: none;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    background-color: #ff641e;
    &.js30 {
      background-color: $js30-color;
      &.outline {
        border-color: $js30-color;
        color: $js30-color;
      }
    }
    &.xy60 {
      background-color: $xy60-color;
      &.outline {
        border-color: $xy60-color;
        color: $xy60-color;
      }
    }
    &.kx90 {
      background-color: $kx90-color;
      &.outline {
        border-color: $kx90-color;
        color: $kx90-color;
      }
    }
    &.sk120 {
      background-color: $sk120-color;
      &.outline {
        border-color: $sk120-color;
        color: $sk120-color;
      }
    }
    &.jnd210 {
      background-color: $jnd210-color;
      &.outline {
        border-color: $jnd210-color;
        color: $jnd210-color;
      }
    }
    &.tw300 {
      background-color: $tw300-color;
      &.outline {
        border-color: $tw300-color;
        color: $tw300-color;
      }
    }
    &.outline {
      border: 1px solid#ff641e;
      color: #ff641e;
      background-color: #fff;
    }
  }

  .auto-join-main {
    border-radius: 5px;
    border: 1px solid #d5d5d5;
    margin-top: 20px;
    .auto-main-con {
      padding: 39px 0 0 30px;
      .form-input {
        width: 200px;
      }
      .ant-form-item-label {
        width: 100px;
        text-align: left;
      }
      .gray-font {
        font-size: 15px;
        color: #747474;
        line-height: 15px;
      }
      .ant-form-item {
        margin-bottom: 14px;
      }
      .tip {
        font-size: 14px;
        color: rgb(110, 110, 110);
        padding-left: 10px;
        vertical-align: middle;
        margin-top: 5px;
        & + img {
          width: 12px;
          height: 12px;
          vertical-align: middle;
          margin-left: 3px;
        }
      }
    }
    .select-ops {
      .ant-col {
        margin-right: 46px;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
  .autojoin-list {
    width: 100%;
    margin-top: 20px;

    border-collapse: collapse;
    th {
      height: 30px;
      background-color: rgb(244, 245, 249);
      text-align: center;
      font-size: 14px;
      font-weight: normal;
    }
    td {
      height: 50px;
      text-align: center;
      border-bottom: 1px solid lightgray;
    }
  }
}
