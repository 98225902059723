.ucenter-container {
  background-color: #faf7f5;
  padding: 18px 0 40px;
  .ucenter-main {
    width: 1200px;
    margin: 0 auto;
    .coin-icon {
      width: 12px;
      height: auto;
      margin-left: 2px;
    }
    .left-nav {
      width: 250px;
      .nav-title {
        height: 60px;
        background: url('../../assets/ucenter/nav-title.png') no-repeat;
        background-size: 100% 100%;
        font-size: 0;
      }
      .nav-menu {
        background: #ffffff;
        box-shadow: 0px 2px 16px 0px rgba(150, 88, 60, 0.1);
        border-radius: 0 0 6px 6px;
        padding: 18px 0 24px;
        & > li {
          width: 210px;
          height: 54px;
          background: #f4f4f4;
          margin: 10px auto 0;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          font-style: normal;
          &.current {
            background-color: #e4920d;
            color: #fff;
          }
        }
      }
    }
    .right-main {
      width: 932px;
      background: #ffffff;
      box-shadow: 0px 2px 16px 0px rgba(150, 88, 60, 0.1);
      border-radius: 6px;
      .right-title {
        height: 48px;
        background: url('../../assets/ucenter/main-title.png') no-repeat;
        background-size: 100% 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        line-height: 48px;
        text-align: left;
        font-style: normal;
        padding-left: 20px;
      }
      .sub-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #666666;
        line-height: 25px;
        font-style: normal;
        text-align: center;
        margin-top: 38px;
        & > span {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            width: 17px;
            height: 16px;
            background: url(../../assets/ucenter/star-icon.png) no-repeat;
            background-size: 100% 100%;
            left: -23px;
            top: 50%;
            margin-top: -8px;
          }
          &::after {
            content: '';
            position: absolute;
            width: 17px;
            height: 16px;
            background: url(../../assets/ucenter/star-icon.png) no-repeat;
            background-size: 100% 100%;
            right: -23px;
            top: 50%;
            margin-top: -8px;
          }
        }
      }
      .info-box {
        width: 892px;
        height: 140px;
        background: #f4f4f4;
        border-radius: 4px;
        margin: 26px auto;
        .avatar-box {
          width: 179px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 110px;
            border: 1px dashed #c7c7c7;
            right: 0;
            top: 15px;
          }
          .avatar-img {
            width: 86px;
            height: 86px;
            position: relative;
            .edit-icon {
              position: absolute;
              width: 18px;
              height: 18px;
              right: 2px;
              bottom: 2px;
              cursor: pointer;
            }
          }
        }
        .middle-box,
        .right-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 46px;
          width: 234px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          white-space: nowrap;
          & > div {
            margin-bottom: 12px;
            &:last-child {
              margin-bottom: 0;
            }
            .balck {
              color: #333333;
            }
            .help-icon {
              margin-left: 5px;
              cursor: pointer;
              color: #ff641e;
              .anticon-question-circle {
                margin-left: 5px;
              }
            }
          }
        }
      }
      .data-box {
        padding-left: 20px;
        margin-top: 16px;
        margin-bottom: 110px;
        & > .ant-col {
          width: 295px;
          height: 158px;
          background: #f5f5f5;
          border-radius: 4px;
          margin-right: 5px;
          padding-top: 22px;
          margin-bottom: 5px;
        }
      }
      .data-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #888888;
        line-height: 20px;
        text-align: center;
        font-style: normal;
      }
      .data-num {
        font-family: DIN, DIN;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        margin-top: 14px;
      }
      .data-bottom {
        margin-top: 22px;
        border-top: 1px solid #e3e3e3;
        height: 57px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #d95316;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        a {
          color: #d95316;
        }
        .split {
          padding: 0 10px;
        }
      }
      .form-box {
        margin-top: 40px;
        .form-lable {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          font-style: normal;
          width: 70px;
          text-align: right;
        }
        .form-input {
          width: 348px;
          height: 40px;
        }
        .form-btn {
          width: 348px;
          height: 40px;
          background: #d74e10;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          font-style: normal;
          margin: 10px 0 0 327px;
          cursor: pointer;
        }
      }
      .search-box {
        margin-top: 24px;
        padding: 0 20px;
        .ant-select-selection-item {
          line-height: 40px;
        }
        .ant-form-item {
          margin-right: 3px;
        }
        .search-input {
          width: 178px;
          height: 40px;
          .ant-input-number-input,
          .ant-select-selector {
            height: 40px;
          }
        }
        .search-btn1 {
          width: 82px;
          height: 40px;
          background: #d74e10;
          border-radius: 2px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          font-style: normal;
        }
        .search-btn2 {
          width: 152px;
          height: 40px;
          border-radius: 2px;
          border: 1px solid #3399fe;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #3399fe;
          font-style: normal;
          margin-left: 3px;
        }
        .use-btn {
          width: 102px;
          height: 40px;
          background: #faa624;
          border-radius: 2px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          font-style: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .ucenter-table {
        margin: 24px 20px 0;
        th.ant-table-cell {
          background-color: #f5f5f5;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #333333;
          font-style: normal;
          height: 50px;
          border-radius: 0 !important;
        }
        td.ant-table-cell {
          height: 50px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          font-style: normal;
          .gray {
            color: #b3b3b3;
          }
          .copy-btn {
            color: #d74e10;
            margin-left: 4px;
          }
          .coin-icon {
            width: 12px;
            height: auto;
            margin-left: 2px;
          }
          .orange {
            color: #ff641e;
          }
          .green {
            color: green;
          }
          .blue-btn {
            color: #3399fe;
            cursor: pointer;
          }
        }
        tr:nth-of-type(even) {
          td.ant-table-cell {
            background-color: #f5f5f5;
          }
        }
      }
      .page-box {
        margin-top: 15px;
        margin-bottom: 30px;
        & > .ant-col {
          margin: 0 10px;
        }
      }
      .level-box {
        padding: 0 20px;
        margin-top: 30px;
        & > .ant-col {
          text-align: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 50px;
          font-style: normal;
          & > div:first-child {
            height: 50px;
            background: #f5f5f5;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #333333;
            line-height: 50px;
            font-style: normal;
          }
        }
      }
      .sign-btns {
        margin-top: 20px;
        & > .ant-col {
          width: 180px;
          height: 40px;
          background: #d74e10;
          border-radius: 4px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          font-style: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.gray {
            background-color: rgba(215, 78, 16, 0.3);
          }
        }
      }
      .pwd-form {
        padding-left: 234px;
        .ant-form-item-label {
          line-height: 40px;
          & > label {
            width: 70px;
            text-align: right;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #333333;
            font-style: normal;
          }
        }
        .form-btn {
          margin-left: 70px;
        }
      }
      .bank-box {
        margin-top: 30px;
        padding: 0 20px;
        & > .ant-col {
          width: 442px;
          height: 410px;
          background: #fff8f1;
          border-radius: 4px;
          border: 1px solid #f4dec8;
          .bank-title {
            height: 49px;
            background: #d74e10;
            border-radius: 4px 4px 0px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
            font-style: normal;
          }
          .bank-coin {
            text-align: center;
            font-family: DIN, DIN;
            font-weight: 500;
            font-size: 20px;
            color: #e89907;
            line-height: 24px;
            font-style: normal;
            margin-top: 24px;
            .coin-icon {
              width: 15px;
              height: 15px;
              margin-left: 5px;
              vertical-align: middle;
            }
          }
          .bank-sub {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 20px;
            text-align: center;
            font-style: normal;
            margin-top: 9px;
          }
          .num-type {
            padding: 0 40px;
            margin-top: 40px;
            & > .ant-col {
              width: 60px;
              cursor: pointer;
              height: 36px;
              border-radius: 2px;
              border: 1px solid #cccccc;
              display: flex;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 14px;
              font-style: normal;
              justify-content: center;
              align-items: center;
              &.current {
                border: 1px solid #ff641e;
                background: rgba(255, 100, 30, 0.1);
                color: #ff641e;
              }
            }
          }
          .bank-form {
            padding-left: 40px;
            margin-top: 22px;
            &.pwd {
              margin-top: 12px;
            }
            .bank-label {
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              font-size: 14px;
              color: #333333;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
            .bank-input {
              width: 286px;
              height: 40px;
              border-radius: 2px;
              border: 1px solid #cccccc;
              background-color: #fff8f1;
              input {
                background: transparent;
              }
              .ant-input-number-input {
                height: 40px;
              }
            }
          }
          .bank-btn {
            width: 362px;
            height: 40px;
            background: #d74e10;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 24px auto 0;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            font-style: normal;
            cursor: pointer;
          }
        }
      }
    }
    .luck-page {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

// 新弹框
.ucenter-modal {
  .ant-modal-content {
    padding: 0 !important;
    padding-bottom: 36px !important;
    .modal-title {
      height: 50px;
      background: linear-gradient(180deg, #ff9d40 0%, #ff641e 100%);
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      font-style: normal;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 8px 8px 0 0;
    }
    .use-input {
      width: 460px;
      height: 276px;
      border-radius: 6px;
      border: 1px solid #cccccc;
      margin: 26px 30px 0;
    }
    .modal-btns {
      margin-top: 42px;
      & > .ant-col {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #b3b3b3;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 18px;
        cursor: pointer;
        &:first-child {
          background: #ff641e;
          color: #fff;
          border-color: #ff641e;
        }
      }
    }
    .head-box {
      margin: 40px 20px 0;
      & > .ant-col {
        margin: 0 10px 20px;
        width: 86px;
        height: 86px;
        cursor: pointer;
        border-radius: 4px;
        &.current {
          outline: 3px solid #ff641e;
        }
      }
    }

    .level-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      padding-left: 13px;
      margin: 10px 0 0 20px;
      .title-icon {
        width: 8px;
        height: 8px;
        border: 2px solid #ff641e;
        border-radius: 100%;
        margin-right: 7px;
      }
    }
    .level-rule {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666;
      line-height: 25px;
      text-align: left;
      font-style: normal;
      padding: 0 20px 0 40px;
      margin-top: 10px;
    }
    .level-info {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666;
      line-height: 30px;
      text-align: left;
      font-style: normal;
      margin: 15px 30px 0;
      color: #ff641e;
      & > .ant-col {
        margin-right: 24px;
      }
    }
    .level-table {
      margin: 10px 30px 0;
      th.ant-table-cell {
        background-color: #f5f5f5;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        font-style: normal;
        height: 50px;
        border-radius: 0 !important;
      }
      td.ant-table-cell {
        height: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        font-style: normal;
        .table-btn {
          width: 80px;
          height: 30px;
          background: linear-gradient(180deg, #ffb694 0%, #ff641e 100%);
          border-radius: 3px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.disabled {
            background-color: gray;
            cursor: not-allowed;
          }
        }
      }
      tr:nth-of-type(even) {
        td.ant-table-cell {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

.luckGame-modal {
  .ant-modal-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .modal-title {
    width: 245px;
    height: 40px;
    background: url(../../assets/luckGame/modal-title.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    font-style: normal;
    margin: 20px auto 0;
  }
  .check-box {
    width: 910px;
    min-height: 425px;
    border: 1px solid #d9d9d9;
    margin: 24px auto;
    .tabel-title {
      height: 50px;
      background: #e6e6e6;
      border-bottom: 1px solid #d9d9d9;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: center;
      font-style: normal;
      line-height: 50px;
      span {
        color: #ff641e;
      }
      &.xy60 {
        span {
          color: #247cff;
        }
      }
      &.jnd210 {
        span {
          color: #dd0000;
        }
      }
    }
    .table-row1,
    .table-row2,
    .table-row3,
    .table-row4 {
      border-bottom: 1px solid #d9d9d9;
      & > .ant-col {
        width: 39px;
        border-right: 1px solid #d9d9d9;
        text-align: center;
        height: 45px;
        font-family: DIN, DIN;
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 45px;
        text-align: center;
        font-style: normal;
        .light {
          color: #ff641e;
          &.xy60 {
            color: #247cff;
          }
          &.jnd210 {
            color: #dd0000;
          }
        }
        &:last-child {
          border: none;
        }
        &:first-child {
          width: 127px;
        }
      }
    }
    .table-row2 {
      & > .ant-col {
        width: 760px;
        &:last-child {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          font-style: normal;
        }
      }
    }
    .table-row3 {
      & > .ant-col {
        width: 43px;
      }
    }
    .table-row4 {
      & > .ant-col {
        width: 258px;
      }
    }
    .num-box {
      margin-top: 12px;
      .num-bg1,
      .num-bg2,
      .num-bg3,
      .num-bg4 {
        width: 64px;
        height: 58px;
        background: url(../../assets/luckGame/num-bg1.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        font-family: DIN, DIN;
        font-weight: bold;
        font-size: 26px;
        line-height: 38px;
        color: #ffffff;
        font-style: normal;
        padding: 5px;
      }
      .num-bg2 {
        background: url(../../assets/luckGame/num-bg2.png) no-repeat;
        background-size: 100% 100%;
      }
      .num-bg3 {
        background: url(../../assets/luckGame/num-bg3.png) no-repeat;
        background-size: 100% 100%;
      }
      .num-bg4 {
        background: url(../../assets/luckGame/num-bg4.png) no-repeat;
        background-size: 100% 100%;
      }
      .minus-icon {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 26px;
        color: #999999;
        line-height: 37px;
        font-style: normal;
        margin-top: -16px;
      }
    }
  }
  .bet-mode-title {
    text-align: center;
    position: relative;
    margin: 29px 24px 0;
    &::before {
      content: '';
      position: absolute;
      width: 910px;
      height: 1px;
      background-color: #d9d9d9;
      top: 50%;
      left: 0;
    }
    & > span {
      padding: 6px 17px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #333333;
      line-height: 17px;
      font-style: normal;
      background-color: #fff;
      z-index: 2;
      position: relative;
      .orange {
        color: #ff641e;
        &.xy60 {
          color: #247cff;
        }
        &.jnd210 {
          color: #dd0000;
        }
      }
    }
  }
  .mode-list {
    padding: 24px;
    flex-wrap: wrap;
    & > .ant-col {
      min-width: 84px;
      height: 30px;
      background: #e6e6e6;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #333333;
      font-style: normal;
      margin: 0 6px;
      cursor: pointer;
      margin-bottom: 12px;
    }
  }
  .delete-tip {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    margin-top: 31px;
  }
  .bottom-btns {
    margin-top: 46px;
    margin-bottom: 29px;
    & > .ant-col {
      width: 120px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #999999;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      font-style: normal;
      margin: 0 19px;
      cursor: pointer;
      &.orange {
        background-color: #ff641e;
        border-color: #ff641e;
        color: #fff;
      }
    }
  }
  .num-detail {
    margin-top: 18px;
    & > .ant-col {
      margin: 0 4px;
      width: 84px;
      height: 60px;
      background: #f5f5f5;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      margin-bottom: 10px;
      & > div {
        text-align: center;
        line-height: 29px;
        &:first-child {
          height: 29px;
          background: #e6e6e6;
          border-bottom: 1px solid #d9d9d9;
        }
      }
      &.orange {
        background: #ffa376;
        height: 150px;
        color: #fff;
        & > div {
          border-bottom: 1px solid #d9d9d9;
          &:first-child {
            background: #ff641e;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
        &.xy60 {
          background: rgba(36, 124, 255, 0.5);
          & > div {
            &:first-child {
              background: #247cff;
            }
          }
        }
        &.jnd210 {
          background: rgba(221, 0, 0, 0.5);
          & > div {
            &:first-child {
              background: rgb(221, 0, 0);
            }
          }
        }
      }
    }
  }
  .bottom-tip {
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 17px;
    font-style: normal;
    margin-top: 9px;
    margin-bottom: 18px;
    span {
      color: #ff641e;
      vertical-align: middle;
      &.green {
        color: #129c4d;
      }
    }
    &.xy60 {
      span {
        color: #247cff;
        &.red {
          color: #ff641e;
          vertical-align: middle;
        }
        &.green {
          color: #129c4d;
        }
      }
    }
    &.jnd210 {
      span {
        color: #dd0000;
        &.red {
          color: #ff641e;
          vertical-align: middle;
        }
        &.green {
          color: #129c4d;
        }
      }
    }
  }
  .mode-input {
    margin: 12px 0 0 24px;
    width: 910px;
  }
}
