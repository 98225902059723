.banner-contaner {
  width: 100%;
  height: 300px;
  position: relative;
  background-color: #f5c07d;
  .banner-img {
    width: 100%;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}

.index-main {
  width: 1200px;
  margin: 0 auto;
  .step-box {
    height: 110px;
    background: url(../../assets/how-to-play.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 16px;
  }
  .news-box {
    width: 1200px;
    height: 40px;
    background: rgba(255, 100, 30, 0.1);
    border-radius: 6px;
    margin-top: 11px;
    padding: 0 12px 0 8px;
    font-family: PingFang SC, PingFang SC;
    & > .ant-col {
      display: flex;
      align-items: center;
      .news-icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
      .orange {
        color: #ff641e;
      }
      .ant-carousel {
        width: 500px;
        height: 40px;
        .news-list {
          line-height: 40px;
          a {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 14px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }
        }
      }
    }
  }

  .more-btn {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #ff641e;
    line-height: 15px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    .anticon-right {
      color: #ff641e;
    }
    &:hover {
      color: #ff641e;
      .anticon-right {
        color: #ff641e;
      }
    }
  }
  .game-title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #333333;
    line-height: 30px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-top: 16px;
    & > .ant-col {
      font-size: 20px;
    }
  }
  .game-list {
    margin-top: 16px;
    & > .ant-col {
      width: 240px;
      height: 320px;
      position: relative;
      margin-bottom: 16px;
      cursor: pointer;
      .game-img {
        width: 100%;
        height: 100%;
        // border-radius: 10px;
      }
      .game-title {
        width: 240px;
        height: 80px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding-top: 12px;
        .font {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        .btn {
          width: 96px;
          height: 32px;
          background: #ff641e;
          border-radius: 30px 30px 30px 30px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
          font-style: normal;
          text-transform: none;
          text-align: center;
          line-height: 32px;
          margin-top: 10px;
        }
      }
    }
  }
  .coop-title {
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-top: 16px;
    text-align: center;
    span {
      display: inline-block;
      background: url(../../assets/coop.png) right center no-repeat;
      padding: 11px 29px;
    }
  }
}
.coop-list {
  margin-top: 24px;
  margin-bottom: 27px;

  & > .ant-col {
    width: 185px;
    height: 100px;
    background: #fff;
    box-shadow: 2px 1px 10px #d8d8d8;
    margin: 0 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0 10px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
