.trend-container {
  padding-bottom: 20px;
  min-height: calc(100vh - 48px - 72px - 256px);

  .warn-tip {
    margin-top: 15px;
  }
}
.trend-main {
  border-radius: 5px;
  background: #fff;
  margin: 10px auto 0px;
  padding: 0 0 0;
  .radio-box {
    & > label {
      width: 119px;
      height: 38px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  .trend-table {
    margin-top: 10px;
    border-collapse: collapse;

    .yellow {
      background-color: rgb(254, 240, 203);
    }
    .green {
      background-color: rgb(233, 250, 232);
    }
    .blue {
      background-color: rgb(229, 246, 254);
    }
    .dark-green {
      background-color: rgb(76, 176, 80);
    }
    .green-blue {
      background-color: rgb(27, 188, 155);
    }
    .dark-red {
      background-color: rgb(254, 0, 0);
    }
    .orange {
      background-color: rgb(230, 127, 33);
    }
    .gray {
      background-color: rgb(79, 79, 79);
    }
    .dark-pink {
      background-color: rgb(230, 0, 230);
    }
    .pink {
      background-color: rgb(255, 90, 255);
    }
    .red {
      background-color: rgb(255, 79, 79);
    }
    .dark-blue {
      background-color: rgb(1, 90, 255);
    }
    .light-blue {
      background-color: rgb(70, 129, 255);
    }
    .dark-yellow {
      background-color: rgb(255, 180, 0);
    }
    .light-yellow {
      background-color: rgb(255, 215, 121);
    }
    .deep-red {
      background-color: rgb(210, 19, 50);
    }
    .purple {
      background-color: rgb(180, 0, 224);
    }
    th {
      font-weight: normal;
      font-size: 10px;
      width: 27px;
      background-color: rgb(229, 228, 230);
      height: 21px;
      border: 1px solid rgb(189, 189, 189);
      &:first-child {
        width: 66px;
      }
      .th-num {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(1, 141, 248);
        border-radius: 100%;
        background: #fff;
        margin: 0 auto;
      }
    }
    td {
      font-size: 10px;
      height: 22px;
      border: 1px solid rgb(189, 189, 189);
      text-align: center;
      color: #fff;
      font-weight: bold;
      & > div {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:first-child {
        color: rgb(70, 58, 55);
      }
    }
  }
}
