.change-container {
  background-color: #faf7f5;
  padding-bottom: 18px;
  .banner-contaner {
    width: 100%;
    height: 260px;
    position: relative;
    background-color: #f5c07d;
    .banner-img {
      width: 100%;
      height: 260px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
  .change-main {
    width: 1200px;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(150, 88, 60, 0.1);
    border-radius: 6px;
    margin: 0 auto;
    padding: 30px 0 30px 30px;
    min-height: 600px;
    margin-top: 18px;
    .change-list {
      & > .ant-col {
        width: 276px;
        height: 381px;
        background: #faf7f5;
        border-radius: 6px;
        margin-right: 12px;
        .goods-img {
          width: 276px;
          height: 276px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 276px;
            max-height: 276px;
          }
        }
        .item-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          text-align: left;
          font-style: normal;
          margin-top: 15px;
          padding: 0 12px;
        }
        .change-box {
          margin-top: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ff641e;
          line-height: 12px;
          text-align: left;
          font-style: normal;
          padding: 0 12px;
          span {
            vertical-align: middle;
          }
          .coin-icon {
            width: 12px;
            height: 12px;
            margin-left: 5px;
            vertical-align: middle;
          }
          .change-btn {
            width: 88px;
            height: 32px;
            background: #ff641e;
            border-radius: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #fff7f0;
            line-height: 14px;
            font-style: normal;
          }
        }
      }
    }
  }
}
