$js30-color: #ff641e;
$xy60-color: #247cff;
$kx90-color: #55cc00;
$sk120-color: rgb(4, 154, 137);
$jnd210-color: #e52c2c;
$tw300-color: #ae2ce5;

.luckGame-rule-box {
  min-height: 425px;
  border: 1px solid #d9d9d9;
  margin-top: 24px;
  .tabel-title {
    height: 50px;
    background: #e6e6e6;
    border-bottom: 1px solid #d9d9d9;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    line-height: 50px;
    span {
      color: #ff641e;
    }
    &.js30 {
      span {
        color: $js30-color;
      }
    }
    &.xy60 {
      span {
        color: $xy60-color;
      }
    }
    &.kx90 {
      span {
        color: $kx90-color;
      }
    }
    &.sk120 {
      span {
        color: $sk120-color;
      }
    }
    &.jnd210 {
      span {
        color: $jnd210-color;
      }
    }
    &.tw300 {
      span {
        color: $tw300-color;
      }
    }
  }
  .table-row1,
  .table-row2,
  .table-row3,
  .table-row4 {
    border-bottom: 1px solid #d9d9d9;
    & > .ant-col {
      width: 49px;
      border-right: 1px solid #d9d9d9;
      text-align: center;
      height: 50px;
      font-family: DIN, DIN;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 50px;
      text-align: center;
      font-style: normal;
      .light {
        color: #ff641e;
        &.js30 {
          color: $js30-color;
        }
        &.xy60 {
          color: $xy60-color;
        }
        &.kx90 {
          color: $kx90-color;
        }
        &.sk120 {
          color: $sk120-color;
        }
        &.jnd210 {
          color: $jnd210-color;
        }
        &.tw300 {
          color: $tw300-color;
        }
      }
      &:last-child {
        border: none;
      }
      &:first-child {
        width: 145px;
      }
    }
  }
  .table-row2 {
    & > .ant-col {
      width: 886px;
      &:last-child {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        font-style: normal;
      }
    }
  }
  .table-row3 {
    & > .ant-col {
      width: 55px;
    }
  }
  .table-row4 {
    & > .ant-col {
      width: 330px;
    }
  }
  .num-box {
    margin-top: 22px;
    margin-bottom: 10px;
    .num-bg1,
    .num-bg2,
    .num-bg3,
    .num-bg4 {
      width: 64px;
      height: 58px;
      background: url(../../assets/luckGame/num-bg1.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      font-family: DIN, DIN;
      font-weight: bold;
      font-size: 26px;
      line-height: 38px;
      color: #ffffff;
      font-style: normal;
      padding: 5px;
    }
    .num-bg2 {
      background: url(../../assets/luckGame/num-bg2.png) no-repeat;
      background-size: 100% 100%;
    }
    .num-bg3 {
      background: url(../../assets/luckGame/num-bg3.png) no-repeat;
      background-size: 100% 100%;
    }
    .num-bg4 {
      background: url(../../assets/luckGame/num-bg4.png) no-repeat;
      background-size: 100% 100%;
    }
    .minus-icon {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 26px;
      color: #999999;
      line-height: 37px;
      font-style: normal;
      margin-top: -16px;
    }
  }
}
.activity-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  padding-left: 13px;
  margin: 30px 0 0 0;
  .title-icon {
    width: 8px;
    height: 8px;
    border: 2px solid #ff641e;
    border-radius: 100%;
    margin-right: 7px;
  }
}
.activity-rule {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  padding: 0 20px 0 10px;
  margin-top: 14px;
}
