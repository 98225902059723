.invite-container {
  background-color: #faf7f5;
  padding-bottom: 24px;
  .banner-contaner {
    width: 100%;
    height: 260px;
    position: relative;
    background-color: #f5c07d;
    .banner-img {
      width: 100%;
      height: 260px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
  .invite-main {
    width: 1200px;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(150, 88, 60, 0.1);
    border-radius: 6px;
    margin: 0 auto;
    min-height: 780px;
    margin-top: 18px;
    .title {
      height: 48px;
      background: linear-gradient(180deg, #ffb694 0%, #ff641e 100%);
      border-radius: 6px 6px 0 0;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      line-height: 48px;
      padding-left: 20px;
    }
    .invite-box-container {
      margin-top: 20px;
    }
    .invite-box {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      padding: 0 40px 0;
      .blue-btn {
        width: 124px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #3399fe;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #3399fe;
        text-align: center;
        font-style: normal;
        line-height: 30px;
        margin-left: 9px;
        a {
          color: #3399fe;
        }
        cursor: pointer;
      }
      .orange-btn {
        width: 94px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #faa624;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #faa624;
        line-height: 30px;
        text-align: center;
        font-style: normal;
        margin-left: 24px;
        cursor: pointer;
      }
      .invite-icon {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        vertical-align: middle;
      }
    }
    .invite-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      margin-left: 30px;
      .title-icon {
        width: 8px;
        height: 8px;
        border: 2px solid #ff641e;
        border-radius: 100%;
        margin-right: 7px;
      }
    }
    .invite-tip {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      padding-left: 40px;
      margin-top: 12px;
    }
    .table-box {
      padding: 20px 40px 15px;
      .coin-icon {
        width: 12px;
        height: auto;
        margin-left: 2px;
      }
      .rule-table {
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        // font-weight: 600;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        border-collapse: collapse;
        td {
          height: 50px;
          width: 50%;
          text-align: center;
        }
        tr:nth-of-type(odd) {
          td {
            background-color: #f5f5f5;
          }
        }
        &.friends {
          td {
            width: 33.33%;
          }
        }
      }
    }
    .page-box {
      margin-top: 25px;
      text-align: center;
    }
  }
}

.ucenter-modal.red-dialog {
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 30px;
  padding-left: 12px;
  margin: 10px 0;
  .ant-modal-content {
    padding: 50px 40px 40px !important;
  }

  .modal-btns {
    margin-top: 30px !important;
    text-align: center;
    & > .ant-col,
    .ant-btn {
      min-width: 104px;
      padding: 0 10px;
      height: 36px;
      background: #ff641e;
      border-radius: 4px;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin: 0 10px;
      cursor: pointer;
      &.gray {
        background: #ffffff;
        border: 1px solid #cccccc;
        line-height: 34px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
      }
    }
    .ant-btn {
      line-height: initial;
      vertical-align: middle;
      &.gray {
        line-height: initial;
      }
    }
  }
}
