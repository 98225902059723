$js30-color: #ff641e;
$xy60-color: #247cff;
$kx90-color: #55cc00;
$sk120-color: rgb(4, 154, 137);
$jnd210-color: #e52c2c;
$tw300-color: #ae2ce5;
.mode-add-btn {
  width: 84px;
  height: 28px;
  background: #ff641e;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  font-style: normal;
  margin-top: 31px;
  cursor: pointer;
  &.js30 {
    background: $js30-color;
  }
  &.xy60 {
    background: $xy60-color;
  }
  &.kx90 {
    background: $kx90-color;
  }
  &.sk120 {
    background: $sk120-color;
  }
  &.jnd210 {
    background: $jnd210-color;
  }
  &.tw300 {
    background: $tw300-color;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}
